import React, { Component } from "react";
import { Avatar } from "@material-ui/core";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import WinnerBoardIndividuaList from "./WinnerBoardIndividuaList";
import httpService from "../../../../../../apis/httpService";
import ApplicationUtil from "../../../../../../utils/ApplicationUtil";
const styles = (theme) => ({
  head: {
    "& p": {
      fontSize: "20px",
    },
  },

  individualBoard: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: "20px",
    boxShadow: "0px",
    width: "100%",
    paddingTop: "5px",
    // margin:'10px'
  },

  profileImage: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    objectFit: "contain",
  },

  listContainer: {
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    height: "40px",
    width: "100%",
    margin: "10px",
    [theme.breakpoints.down("sm")]: {
      margin: "10px",
      display: "flex",
      width: "97%",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  teamLeader: {
    position: "absolute",
    left: "7%",
    top: "-22px",
  },
  listStyling: {
    textAlign: "left",
    padding: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "20px",
    height: "100%",
    width: "90%",
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
      height: "100%",
      width: "100%",
      margin: "0",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  individualListStyling: {
    fontWeight: "bold",
    textAlign: "left",
    padding: "10px",
    flex: "1",
    [theme.breakpoints.down("sm")]: {},
  },
  rankStyling: {
    fontWeight: "bold",
    color: "white",
    marginRight: "2px",
    width: "20px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "10px",
      width: "9px",
    },
  },
  nameStyling: {
    fontWeight: "bold",
    textAlign: "left",
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    paddingLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "10px",
    },
  },
});

class BoardList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortingKM: true,
    };
  }

  render() {
    const {
      challangeType,
      classes,
      rank,
      distance,
      data,
      winners,
      gender,
      time,
      challengeParameter,
      skipTimeOnUploadActivity,
      AgeWiseData,
      showTopThree,
      oneTimeTeamListData,
      teamLeaderId,
    } = this.props;
    const API_URL = httpService.API_URL();

    return (
      <div
        style={{
          background:
            gender === "FEMALE"
              ? "linear-gradient(90deg, rgb(230, 100, 101), rgb(145, 152, 229))"
              : gender === "MALE"
              ? "linear-gradient(90deg, rgb(3, 83, 164), rgb(0, 212, 255))"
              : "#0077b6",
        }}
        className={classes.individualBoard}
      >
        {winners?.length > 0 && (
          <WinnerBoardIndividuaList
            winners={winners}
            challangeType={challangeType}
            rank={rank}
            challengeParameter={challengeParameter}
            profileImage={winners?.profileImage ? winners.profileImage : ""}
            firstName={winners?.firstName}
            lastName={winners?.lastName}
          />
        )}

        {!showTopThree &&
          data?.map((member, index) => (
            <div className={classes.listContainer}>
              {rank && member?.rank && (
                <span className={classes.rankStyling}>{member.rank}</span>
              )}

              <div
                style={{
                  background:
                    gender === "FEMALE"
                      ? "#737373"
                      : gender === "MALE"
                      ? "#737373"
                      : teamLeaderId === member.runnerId
                      ? "linear-gradient(to left, rgb(253, 245, 238), rgb(242, 184, 136))"
                      : "white",
                  color:
                    gender === "FEMALE"
                      ? "white"
                      : gender === "MALE"
                      ? "white"
                      : teamLeaderId === member.runnerId
                      ? "black"
                      : "grey",
                }}
                className={classes.listStyling}
              >
                <Avatar
                  src={
                    member?.profileLink
                      ? member?.profileLink.includes("https")
                        ? `${member?.profileLink}`
                        : `${API_URL}${member?.profileLink}`
                      : ""
                  }
                  className={classes.profileImage}
                >
                  {member.firstName.charAt(0).toUpperCase()}
                </Avatar>

                <span className={classes.nameStyling}>
                  {`${member.firstName} ${
                    member.lastName ? member.lastName : ""
                  }`}
                </span>

                {(challengeParameter === "DISTANCE" || distance) && (
                  <span
                    className={classes.individualListStyling}
                    style={{ flex: challengeParameter.length >= 2 ? 1 : 2 }}
                  >
                    {ApplicationUtil.formatNumberWithCommas(
                      parseFloat(member.totalActivityDistance / 1000).toFixed(2)
                    ) ||
                      parseFloat(member.totalActivityDistance / 1000).toFixed(
                        2
                      ) + " Km "}
                  </span>
                )}
                {/* if stair steeper is present */}
                {challengeParameter === "STEPS" &&
                  challangeType === "STAIR_STEPPER" && (
                    <span className={classes.individualListStyling}>
                      {member?.completionTime}
                    </span>
                  )}

                {challengeParameter === "STEPS" &&
                  challangeType !== "STAIR_STEPPER" && (
                    <span className={classes.individualListStyling}>
                      {ApplicationUtil.formatNumberWithCommas(
                        member.totalSteps
                      ) || member.totalSteps}
                    </span>
                  )}
                {challengeParameter === "WELLNESS_POINT" && (
                  <span className={classes.individualListStyling}>
                    {ApplicationUtil.formatNumberWithCommas(
                      member.totalWellnessPoint
                    ) || member.totalWellnessPoint}
                  </span>
                )}
                {challengeParameter === "CALORIE" && (
                  <span className={classes.individualListStyling}>
                    {ApplicationUtil.formatNumberWithCommas(
                      member.totalCalories
                    ) || member.totalCalories}
                  </span>
                )}
                {challengeParameter === "ELEVATION" && (
                  <span className={classes.individualListStyling}>
                    {member.totalElevationGain}
                  </span>
                )}
                {(challengeParameter === "TIME" ||
                  challengeParameter === undefined ||
                  time) &&
                  !skipTimeOnUploadActivity && (
                    <span className={classes.individualListStyling}>
                      {ApplicationUtil.formatNumberWithCommas(
                        member.completionTime
                      ) || member.completionTime}
                    </span>
                  )}
              </div>
            </div>
          ))}

        {AgeWiseData?.length &&
          AgeWiseData?.map((member, index) => (
            <div className={classes.listContainer}>
              {rank && member?.rank && (
                <span className={classes.rankStyling}>{member.rank}</span>
              )}

              <div
                style={{
                  backgroundColor:
                    gender === "FEMALE"
                      ? "#737373"
                      : gender === "MALE"
                      ? "#737373"
                      : "white",
                  color:
                    gender === "FEMALE"
                      ? "white"
                      : gender === "MALE"
                      ? "white"
                      : "grey",
                }}
                className={classes.listStyling}
              >
                <Avatar
                  src={
                    member?.profileLink
                      ? member?.profileLink.includes("https")
                        ? `${member?.profileLink}`
                        : `${API_URL}${member?.profileLink}`
                      : ""
                  }
                  className={classes.profileImage}
                >
                  {member.firstName.charAt(0).toUpperCase()}
                </Avatar>

                <span className={classes.nameStyling} style={{ flex: 2 }}>
                  {`${member.firstName} ${
                    member.lastName ? member.lastName : ""
                  }`}
                </span>

                {challengeParameter === "STEPS" ? (
                  <span
                    className={classes.individualListStyling}
                    style={{ flex: 2 }}
                  >
                    {member?.totalSteps &&
                      ApplicationUtil?.formatNumberWithCommas(
                        member?.totalSteps
                      )}
                  </span>
                ) : (
                  <span
                    className={classes.individualListStyling}
                    style={{ flex: 2 }}
                  >
                    {member?.totalDistance &&
                      ApplicationUtil?.formatNumberWithCommas(
                        member?.totalDistance
                      )}
                  </span>
                )}
              </div>
            </div>
          ))}

        {oneTimeTeamListData &&
          oneTimeTeamListData?.map((teamMember, index) => {
            return (
              <div className={classes.listContainer}>
                <div
                  style={{
                    backgroundColor:
                      gender === "FEMALE"
                        ? "#737373"
                        : gender === "MALE"
                        ? "#737373"
                        : "white",
                    color:
                      gender === "FEMALE"
                        ? "white"
                        : gender === "MALE"
                        ? "white"
                        : "grey",
                  }}
                  className={classes.listStyling}
                >
                  <Avatar
                    src={
                      teamMember?.profileLink
                        ? teamMember?.profileLink.includes("https")
                          ? `${teamMember?.profileLink}`
                          : `${API_URL}${teamMember?.profileLink}`
                        : ""
                    }
                    className={classes.profileImage}
                  >
                    {teamMember?.runnerName?.charAt(0).toUpperCase() ||
                      teamMember?.teamName?.charAt(0).toUpperCase() ||
                      teamMember?.firstName?.charAt(0).toUpperCase()}
                  </Avatar>

                  <span className={classes.nameStyling} style={{ flex: 2 }}>
                    {`${
                      teamMember.runnerName ||
                      teamMember?.teamName ||
                      `${teamMember.firstName} ${
                        teamMember.lastName ? teamMember.lastName : ""
                      }`
                    }`}
                  </span>

                  <span
                    className={classes.individualListStyling}
                    style={{ flex: 2 }}
                  >
                    {(teamMember?.cumulativeSteps &&
                      ApplicationUtil?.formatNumberWithCommas(
                        teamMember?.cumulativeSteps
                      )) ||
                      (teamMember?.totalTeamSteps &&
                        ApplicationUtil?.formatNumberWithCommas(
                          teamMember?.totalTeamSteps
                        )) ||
                      (teamMember?.totalSteps &&
                        ApplicationUtil?.formatNumberWithCommas(
                          teamMember?.totalSteps
                        ))}
                  </span>

                  {(teamMember?.daysTaken || teamMember?.dayToReach) && (
                    <span
                      className={classes.individualListStyling}
                      style={{ flex: 2 }}
                    >
                      {teamMember?.daysTaken
                        ? `${teamMember?.daysTaken} Days`
                        : teamMember?.dayToReach
                        ? `${teamMember?.dayToReach} Days`
                        : null}
                    </span>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(BoardList);
