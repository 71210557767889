import React, { useEffect, useState } from "react";
import "./../../../../styles/organizer.css";
import Avatar from "@material-ui/core/Avatar";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ApplicationUtil from "./../../../../utils/ApplicationUtil";
import getUserDetails from "./../../../../apis/userService";
import { useDispatch, useSelector } from "react-redux";
import { removeSupportUserDetails } from "../../../../redux/supportUser";
import { BackArrowSVGIcon } from "../../../common/SvgIcon";
import { IconButton } from "@material-ui/core";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import httpService from "../../../../apis/httpService";
function OrganizerCommonHeader(props) {
  const { title, logo, hideProfile, isProfile, homeScreen } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const { history } = props; //useHistory();
  const API_URL_FILE = httpService.API_URL();
  let isProgramDashboard = window.location.href
    .toLowerCase()
    .includes("programdashboard");
  let selectedProgramDetails = ApplicationUtil.getSupportsProgramDetails();
  const { supportUser } = useSelector((state) => state.supportUser);
  const supportDTO = useSelector((state) => state.supportUser.supportDTO);
  const supportSelectedEvent = useSelector(
    (state) => state.supportUser.supportSelectedEvent
  );
  // Vishal - Defect_Id_574 fixed--> if headerSelect is program then use program data - (supportSelectedProgram) ... line 72
  const supportSelectedProgram = useSelector(
    (state) => state.supportUser.supportSelectedProgram
  );

  const dispatch = useDispatch();
  const [userProfile, setUserProfile] = React.useState(supportUser);
  const [linkClass, setLinkClass] = useState("EVENT");

  useEffect(() => {
    // getUserDetails(token, userId).then((res) => {
    //   setProfilePicture(res?.data?.profilePicture?.imageData);
    // });
    let selectedLink = localStorage.getItem("headerLinkSelected");

    setLinkClass(
      selectedLink === "Challenges" || selectedLink === "Challenge"
        ? "CHALLENGE"
        : selectedLink === "Training"
        ? "TRAINING"
        : selectedLink === "Program"
        ? "PROGRAM"
        : "EVENT"
    );
  }, []);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleProfile = () => {
    history.push({ pathname: "/profile", state: { view: "organizer" } });
  };
  const handleLogout = () => {
    setAnchorEl(null);
    localStorage.clear();
    dispatch(removeSupportUserDetails());
    ApplicationUtil.go("/organizer");
  };
  const eventName = title
    ? title
    : linkClass === "PROGRAM"
    ? //Vishal- Defect_Id_593
      supportSelectedEvent?.name ?? supportSelectedProgram?.name
    : supportSelectedEvent?.name;
  const userName = userProfile?.firstName + " " + userProfile?.lastName;
  const links =
    supportUser?.role === "ORGANIZER_ADMIN" && supportUser?.isProgramAdmin
      ? [{ label: "PROGRAM", value: "PROGRAM" }]
      : supportUser?.role === "ORGANIZER_ADMIN"
      ? [
          { label: "EVENTS", value: "EVENT" },
          { label: "CHALLENGES", value: "CHALLENGE" },
          { label: "TRAINING", value: "TRAINING" },
        ]
      : [
          { label: "EVENTS", value: "EVENT" },
          { label: "CHALLENGES", value: "CHALLENGE" },
          { label: "TRAINING", value: "TRAINING" },
          { label: "PROGRAM", value: "PROGRAM" },
        ];
  const handleLink = (event) => {
    localStorage.setItem(
      "headerLinkSelected",
      event.charAt(0).toUpperCase() + event.slice(1).toLowerCase()
    );
    props.showEventsHandler(event);
    setLinkClass(event);
  };


  return (
    <>
      <header className="organizer__header">
        {isProfile ? (
          <div className="profile_left_heading">
            <BackArrowSVGIcon
              color="inherit"
              style={{ paddingLeft: 0, height: 22, width: 22 }}
              onClick={(e) => {
                props.backNavigate();
              }}
            />

            <p>{eventName}</p>
          </div>
        ) : (
          <div
            className="left__heading"
            style={{
              justifyContent: isProgramDashboard ? "start" : "space-between",
              gap: isProgramDashboard && "10px",
            }}
          >
            {/* {logo && ( */}
            <img
              onClick={() => history.push("/organizer/eventlist")}
              src={
                supportDTO?.image
                  ? // ?.image?.imageDTO !== "undefined"
                    "data:image/png;base64," + supportDTO?.image?.imageData
                  : supportDTO?.organizerLogo
                  ? API_URL_FILE + supportDTO?.organizerLogo
                  : "/assets/images/nd-logo-192X192.png"
              }
              className="logo"
              alt="logo"
            />
            {isProgramDashboard && (
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <div>
                  {selectedProgramDetails?.organizers?.map((organizer) => {
                    return (
                      <img
                        src={`${httpService.API_URL()}${
                          organizer?.organizerLogo
                        }`}
                        alt="organizer-logo"
                        height="35"
                        // width="70"
                        style={{ objectFit: "cover" }}
                      />
                    );
                  })}
                </div>
                {/*Vishal- Resolved Defect_Id_691 - showing program name in corporate program dashboard */}
                <div>
                  {" "}
                  {isProgramDashboard && <p className="">{eventName}</p>}
                </div>
              </div>
            )}
            {/* )} */}
            {homeScreen ? (
              <div className="organizer__header--links">
                {links?.map((a) => (
                  <div className="link">
                    <p
                      className={linkClass === a.value ? "p-bg" : ""}
                      onClick={() => handleLink(a.value)}
                    >
                      {a.label}
                    </p>
                  </div>
                ))}
              </div>
            ) : (
              <>
                {!isProgramDashboard && (
                  <p className="eventName">{eventName}</p>
                )}
              </>
            )}
          </div>
        )}

        <div className="organizer__header--user">
          <Avatar
            alt={supportDTO !== "undefined" ? supportDTO?.name : userName}
            src={
              userProfile?.profilePicture
                ? "data:image/png;base64," + userProfile?.profilePicture
                : null
            }
          />
          <div style={{ marginLeft: "5px" }}>
            <span>{userName}</span>
            <br />
            <span style={{ fontSize: "12px" }}>
              {supportDTO ? supportDTO?.name : ""}
            </span>
          </div>
          <ArrowDropDownIcon onClick={handleClick} className="user__icon" />
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            // keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            className="header__user--menu"
          >
            {!hideProfile && (
              <MenuItem onClick={handleProfile}>Profile</MenuItem>
            )}
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </div>
      </header>
    </>
  );
}

export default compose(withRouter)(OrganizerCommonHeader);
