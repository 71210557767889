class ApplicationConstants {
  constructor() {
    if (!this.instance) {
      this.instance = this;
    }
    return this.instance;
  }

  static isProd = process.env.NODE_ENV === 'production'
  static NewGoogleMapKey = "AIzaSyBG3E9JmZgd3jXTZNr58b3jtYm1C1G2hew";
  static OldGoogleMapKey = "AIzaSyAMGNVFNpgh49eugFph2FAacw-sl0FogsE";
  // "AIzaSyBpifvQtIU_o6TOPe2cwHec21F8T43MrP8"; //mine
  static CommonEventId = 22;
  static paymentGatewayKey = "rzp_live_25F7Ntb2hv2d8v";
  static Hours = [
    { label: "00", value: "00" },
    { label: "01", value: "01" },
    { label: "02", value: "02" },
    { label: "03", value: "03" },
    { label: "04", value: "04" },
    { label: "05", value: "05" },
    { label: "06", value: "06" },
    { label: "07", value: "07" },
    { label: "08", value: "08" },
    { label: "09", value: "09" },
    { label: "10", value: "10" },

    { label: "11", value: "11" },
    { label: "12", value: "12" },
    { label: "13", value: "13" },
    { label: "14", value: "14" },
    { label: "15", value: "15" },
    { label: "16", value: "16" },
    { label: "17", value: "17" },
    { label: "18", value: "18" },
    { label: "19", value: "19" },
    { label: "20", value: "20" },

    { label: "21", value: "21" },
    { label: "22", value: "22" },
    { label: "23", value: "23" },
    { label: "24", value: "24" },
    { label: "25", value: "25" },
    { label: "26", value: "26" },
    { label: "27", value: "27" },
    { label: "28", value: "28" },
    { label: "29", value: "29" },
    { label: "30", value: "30" },

    { label: "31", value: "31" },
    { label: "32", value: "32" },
    { label: "33", value: "33" },
    { label: "34", value: "34" },
    { label: "35", value: "35" },
    { label: "36", value: "36" },
    { label: "37", value: "37" },
    { label: "38", value: "38" },
    { label: "39", value: "39" },
    { label: "40", value: "40" },

    { label: "41", value: "41" },
    { label: "42", value: "42" },
    { label: "43", value: "43" },
    { label: "44", value: "44" },
    { label: "45", value: "45" },
    { label: "46", value: "46" },
    { label: "47", value: "47" },
    { label: "48", value: "48" },
    { label: "49", value: "49" },
    { label: "50", value: "50" },

    { label: "51", value: "51" },
    { label: "52", value: "52" },
    { label: "53", value: "53" },
    { label: "54", value: "54" },
    { label: "55", value: "55" },
    { label: "56", value: "56" },
    { label: "57", value: "57" },
    { label: "58", value: "58" },
    { label: "59", value: "59" },
    { label: "60", value: "60" },

    { label: "61", value: "61" },
    { label: "62", value: "62" },
    { label: "63", value: "63" },
    { label: "64", value: "64" },
    { label: "65", value: "65" },
    { label: "66", value: "66" },
    { label: "67", value: "67" },
    { label: "68", value: "68" },
    { label: "69", value: "69" },
    { label: "70", value: "70" },

    { label: "71", value: "71" },
    { label: "72", value: "72" },
    { label: "73", value: "73" },
    { label: "74", value: "74" },
    { label: "75", value: "75" },
    { label: "76", value: "76" },
    { label: "77", value: "77" },
    { label: "78", value: "78" },
    { label: "79", value: "79" },
    { label: "80", value: "80" },

    { label: "81", value: "81" },
    { label: "82", value: "82" },
    { label: "83", value: "83" },
    { label: "84", value: "84" },
    { label: "85", value: "85" },
    { label: "86", value: "86" },
    { label: "87", value: "87" },
    { label: "88", value: "88" },
    { label: "89", value: "89" },
    { label: "90", value: "90" },

    { label: "91", value: "91" },
    { label: "92", value: "92" },
    { label: "93", value: "93" },
    { label: "94", value: "94" },
    { label: "95", value: "95" },
    { label: "96", value: "96" },
    { label: "97", value: "97" },
    { label: "98", value: "98" },
    { label: "99", value: "99" },
    // { label: "100", value: "100" },
  ];

  static valueArray = [
    { label: "00", value: "00" },
    { label: "01", value: "01" },
    { label: "02", value: "02" },
    { label: "03", value: "03" },
    { label: "04", value: "04" },
    { label: "05", value: "05" },
    { label: "06", value: "06" },
    { label: "07", value: "07" },
    { label: "08", value: "08" },
    { label: "09", value: "09" },
    { label: "10", value: "10" },
    { label: "11", value: "11" },
    { label: "12", value: "12" },
    { label: "13", value: "13" },
    { label: "14", value: "14" },
    { label: "15", value: "15" },
    { label: "16", value: "16" },
    { label: "17", value: "17" },
    { label: "18", value: "18" },
    { label: "19", value: "19" },
    { label: "20", value: "20" },
    { label: "21", value: "21" },
    { label: "22", value: "22" },
    { label: "23", value: "23" },
    { label: "24", value: "24" },
    { label: "25", value: "25" },
    { label: "26", value: "26" },
    { label: "27", value: "27" },
    { label: "28", value: "28" },
    { label: "29", value: "29" },
    { label: "30", value: "30" },
    { label: "31", value: "31" },
    { label: "32", value: "32" },
    { label: "33", value: "33" },
    { label: "34", value: "34" },
    { label: "35", value: "35" },
    { label: "36", value: "36" },
    { label: "37", value: "37" },
    { label: "38", value: "38" },
    { label: "39", value: "39" },
    { label: "40", value: "40" },
    { label: "41", value: "41" },
    { label: "42", value: "42" },
    { label: "43", value: "43" },
    { label: "44", value: "44" },
    { label: "45", value: "45" },
    { label: "46", value: "46" },
    { label: "47", value: "47" },
    { label: "48", value: "48" },
    { label: "49", value: "49" },
    { label: "50", value: "50" },
    { label: "51", value: "51" },
    { label: "52", value: "52" },
    { label: "53", value: "53" },
    { label: "54", value: "54" },
    { label: "55", value: "55" },
    { label: "56", value: "56" },
    { label: "57", value: "57" },
    { label: "58", value: "58" },
    { label: "59", value: "59" },
  ];

  static CountryCodes = [
    {
      label: "+93(Afghanistan)",
      value: "93",
      code: "+93",
      country: "Afghanistan",
    },
    { label: "+355(Albania)", value: "355" },
    { label: "+213(Algeria)", value: "213" },
    { label: "+1(684)(American Samoa)", value: "1(684)" },
    { label: "+376(Andorra)", value: "376" },
    { label: "+244(Angola)", value: "244" },
    { label: "+1(264)(Anguilla)", value: "1(264)" },
    { label: "+1(268)(Antigua and Barbuda)", value: "1(268)" },
    { label: "+54(Argentina)", value: "54" },
    { label: "+374(Armenia)", value: "374" },
    { label: "+61(Australia)", value: "61" },
    { label: "+672(Australian Territory)", value: "672" },
    { label: "+43(Austria)", value: "43" },
    { label: "+994(Azerbaijan)", value: "994" },

    { label: "+1(242)(Bahamas)", value: "1(242)" },
    { label: "+973(Bahrain)", value: "973" },
    { label: "+880(Bangladesh)", value: "880" },
    { label: "+1(246)(Barbados)", value: "1(246)" },
    { label: "+375(Belarus)", value: "375" },
    { label: "+32(Belgium)", value: "32" },
    { label: "+501(Belize)", value: "501" },
    { label: "+229(Benin)", value: "229" },
    { label: "+1(441)(Bermuda)", value: "1(441)" },
    { label: "+975(Bhutan)", value: "975" },
    { label: "+591(Bolivia)", value: "591" },
    { label: "+387(Bosnia/Herzegovina)", value: "387" },
    { label: "+267(Botswana)", value: "267" },
    { label: "+55(Brazil)", value: "55" },
    { label: "+1(284)(British Virgin Islands)", value: "1(284)" },
    { label: "+673(Brunei)", value: "673" },
    { label: "+359(Bulgaria)", value: "359" },
    { label: "+226(Burkina Faso)", value: "226" },
    { label: "+257(Burundi)", value: "257" },

    { label: "+855(Cambodia)", value: "855" },
    { label: "+237(Cameroon)", value: "237" },
    { label: "+1(Canada)", value: "1" },
    { label: "+238(Cape Verde Islands)", value: "238" },
    { label: "+1(345)(Cayman Islands)", value: "1(345)" },
    { label: "+236(Central African Republic)", value: "236" },
    { label: "+235(Chad)", value: "235" },
    { label: "+56(Chile)", value: "56" },
    { label: "+86(China)", value: "86" },
    { label: "+57(Colombia)", value: "57" },
    { label: "+269(Comoros)", value: "269" },
    { label: "+243(Congo (DROC), Democratic Republic of)", value: "243" },
    { label: "+242(Congo (ROC), Republic of)", value: "242" },
    { label: "+682(Cook Islands)", value: "682" },
    { label: "+506(Costa Rica)", value: "506" },
    { label: "+385(Croatia)", value: "385" },
    { label: "+53(Cuba)", value: "53" },
    { label: "+357(Cyprus)", value: "357" },
    { label: "+420(Czech Republic)", value: "420" },

    { label: "+45(Denmark)", value: "45" },
    { label: "+246(Diego Garcia)", value: "246" },
    { label: "+253(Djibouti)", value: "253" },
    { label: "+1(767)(Dominica)", value: "1(767)" },
    { label: "+1(Dominican Republic)", value: "1" },

    { label: "+593(Ecuador)", value: "593" },
    { label: "+20(Egypt)", value: "20" },
    { label: "+503(El Salvador)", value: "503" },
    { label: "+240(Equatorial Guinea)", value: "240" },
    { label: "+291(Eritrea)", value: "291" },
    { label: "+372(Estonia)", value: "372" },
    { label: "+251(Ethiopia)", value: "251" },
    { label: "+0131(Edinburgh)", value: "0131" },

    { label: "+500(Falkland Islands)", value: "500" },
    { label: "+298(Faroe Islands)", value: "298" },
    { label: "+679(Fiji)", value: "679" },
    { label: "+358(Finland)", value: "358" },
    { label: "+33(France)", value: "33" },
    { label: "+594(French Guiana)", value: "594" },

    { label: "+220(Gabon)", value: "220" },
    { label: "+995(Georgia)", value: "995" },
    { label: "+49(Germany)", value: "49" },
    { label: "+233(Ghana)", value: "233" },
    { label: "+350(Gibraltar)", value: "350" },
    { label: "+30(Greece)", value: "30" },
    { label: "+299(Greenland)", value: "299" },
    { label: "+1(473)(Grenada)", value: "1(473)" },
    { label: "+590(Guadeloupe (Fr. W. Indies))", value: "590" },
    { label: "+502(Guatemala)", value: "502" },
    { label: "+44(Guernsey)", value: "44" },
    { label: "+224(Guinea)", value: "224" },
    { label: "+245(Guinea-Bissau)", value: "245" },
    { label: "+592(Guyana)", value: "592" },

    { label: "+509(Haiti)", value: "509" },
    { label: "+504(Honduras)", value: "504" },
    { label: "+852(Hong Kong)", value: "852" },
    { label: "+36(Hungary)", value: "36" },

    { label: "+354(Iceland)", value: "354" },
    { label: "+91(India)", value: "91" },
    { label: "+62(Indonesia)", value: "62" },
    { label: "+98(Iran)", value: "98" },
    { label: "+964(Iraq)", value: "964" },
    { label: "+353(Ireland)", value: "353" },
    { label: "+44(Isle of Man)", value: "44" },
    { label: "+972(Israel)", value: "972" },
    { label: "+39(Italy)", value: "39" },
    { label: "+225(Ivory Coast)", value: "225" },

    { label: "+1(876)(Jamaica)", value: "1(876)" },
    { label: "+81(Japan)", value: "81" },
    { label: "+44(Jersey)", value: "44" },
    { label: "+962(Jordan)", value: "962" },

    { label: "+7(Kazakhstan)", value: "7" },
    { label: "+254(Kenya)", value: "254" },
    { label: "+686(Kiribati)", value: "686" },
    { label: "+850(Korea (North))", value: "850" },
    { label: "+82(Korea (South))", value: "82" },
    { label: "+965(Kuwait)", value: "965" },
    { label: "+996(Kyrgyzstan)", value: "996" },

    { label: "+856(Laos)", value: "856" },
    { label: "+371(Latvia)", value: "371" },
    { label: "+961(Lebanon)", value: "961" },
    { label: "+266(Lesotho)", value: "266" },
    { label: "+231(Liberia)", value: "231" },
    { label: "+218(Libya)", value: "218" },
    { label: "+423(Liechtenstein)", value: "423" },
    { label: "+370(Lithuania)", value: "370" },
    { label: "+352(Luxembourg)", value: "352" },

    { label: "+853(Macau)", value: "853" },
    { label: "+389(Macedonia)", value: "389" },
    { label: "+261(Madagascar)", value: "261" },
    { label: "+265(Malawi)", value: "265" },
    { label: "+60(Malaysia)", value: "60" },
    { label: "+960(Maldives)", value: "960" },
    { label: "+223(Mali)", value: "223" },
    { label: "+356(Malta)", value: "356" },
    { label: "+692(Marshall Islands)", value: "692" },
    { label: "+596(Martinique)", value: "596" },
    { label: "+222(Mauritania)", value: "222" },
    { label: "+230(Mauritius)", value: "230" },
    { label: "+52(Mexico)", value: "52" },
    { label: "+691(Micronesia)", value: "691" },
    { label: "+373(Moldova)", value: "373" },
    { label: "+377(Monaco)", value: "377" },
    { label: "+976(Mongolia)", value: "976" },
    { label: "+382(Montenegro)", value: "382" },
    { label: "+1(664)(Montserrat)", value: "1(664)" },
    { label: "+212(Morocco)", value: "212" },
    { label: "+258(Mozambique)", value: "258" },
    { label: "+95(Myanmar (Burma))", value: "95" },

    { label: "+264(Namibia)", value: "264" },
    { label: "+674(Nauru)", value: "674" },
    { label: "+977(Nepal)", value: "977" },
    { label: "+31(Netherlands)", value: "31" },
    {
      label: "+599(Netherlands Antilles (Bonaire, Curacao, Saba, St. Eustis))",
      value: "599",
    },
    { label: "+687(New Caledonia)", value: "687" },
    { label: "+64(New Zealand)", value: "64" },
    { label: "+505(Nicaragua)", value: "505" },
    { label: "+227(Niger)", value: "227" },
    { label: "+234(Nigeria)", value: "234" },
    { label: "+1(670)(Northern Mariana Islands)", value: "1(670)" },
    { label: "+47(Norway)", value: "47" },

    { label: "+968(Oman)", value: "968" },

    { label: "+92(Pakistan)", value: "92" },
    { label: "+680(Palau)", value: "680" },
    { label: "+970(Palestinian Authority)", value: "970" },
    { label: "+507(Panama)", value: "507" },
    { label: "+675(Papua New Guinea)", value: "675" },
    { label: "+595(Paraguay)", value: "595" },
    { label: "+51(Peru)", value: "51" },
    { label: "+63(Philippines)", value: "63" },
    { label: "+48(Poland)", value: "48" },
    { label: "+351(Portugal)", value: "351" },

    { label: "+974(Qatar)", value: "974" },

    { label: "+262(Reunion)", value: "262" },
    { label: "+40(Romania)", value: "40" },
    { label: "+7(Russia)", value: "7" },
    { label: "+250(Rwanda)", value: "250" },

    { label: "+1(670)(Saipan (Northern Mariana Islands))", value: "1(670)" },
    { label: "+685(Samoa)", value: "685" },
    { label: "+378(San Marino)", value: "378" },
    { label: "+239(Sao Tome/Principe)", value: "239" },
    { label: "+966(Saudi Arabia)", value: "966" },
    { label: "+221(Senegal)", value: "221" },
    { label: "+381(Serbia)", value: "381" },
    { label: "+248(Seychelles)", value: "248" },
    { label: "+232(Sierra Leone)", value: "232" },
    { label: "+65(Singapore)", value: "65" },
    { label: "+1(721)(Sint Maarten)", value: "1(721)" },
    { label: "+421(Slovakia)", value: "421" },
    { label: "+386(Slovenia)", value: "386" },
    { label: "+677(Solomon Islands)", value: "677" },
    { label: "+27(South Africa)", value: "27" },
    { label: "+211(South Sudan)", value: "211" },
    { label: "+34(Spain)", value: "34" },
    { label: "+94(Sri Lanka)", value: "94" },
    { label: "+508(St Pierre/Miquelon)", value: "508" },
    { label: "+1(869)(St. Kitts & Nevis)", value: "1(869)" },
    { label: "+1(758)(St. Lucia)", value: "1(758)" },
    { label: "+1(784)(St. Vincent/Grenadines)", value: "1(784)" },
    { label: "+249(Sudan)", value: "249" },
    { label: "+597(Suriname)", value: "597" },
    { label: "+268(Swaziland)", value: "268" },
    { label: "+46(Sweden)", value: "46" },
    { label: "+41(Switzerland)", value: "41" },
    { label: "+963(Syria)", value: "963" },

    { label: "+886(Taiwan)", value: "886" },
    { label: "+992(Tajikistan)", value: "992" },
    { label: "+255(Tanzania)", value: "255" },
    { label: "+66(Thailand)", value: "66" },
    { label: "+228(Togo)", value: "228" },
    { label: "+690(Tokelau)", value: "690" },
    { label: "+676(Tonga)", value: "676" },
    { label: "+1(868)(Trinidad & Tobago)", value: "1(868)" },
    { label: "+216(Tunisia)", value: "216" },
    { label: "+90(Turkey)", value: "90" },
    { label: "+993(Turkmenistan)", value: "993" },
    { label: "+1(649)(Turks and Caicos Islands)", value: "1(649)" },
    { label: "+688(Tuvalu)", value: "688" },

    { label: "+256(Uganda)", value: "256" },
    { label: "+380(Ukraine)", value: "380" },
    { label: "+971(United Arab Emirates)", value: "971" },
    { label: "+44(United Kingdom)", value: "44" },
    { label: "+598(Uruguay)", value: "598" },
    { label: "+998(Uzbekistan)", value: "998" },
    { label: "+1(United States)", value: "1" },
    { label: "+678(Vanuatu)", value: "678" },
    { label: "+58(Venezuela)", value: "58" },
    { label: "+84(Vietnam)", value: "84" },

    { label: "+967(Yemen)", value: "967" },

    { label: "+260(Zambia)", value: "260" },
    { label: "+263(Zimbabwe)", value: "263" },
  ];

  static countries = [
    { label: "Afghanistan", value: "Afghanistan" },
    { label: "Albania", value: "Albania" },
    { label: "Algeria", value: "Algeria" },
    { label: "American Samoa", value: "American Samoa" },
    { label: "Andorra", value: "Andorra" },
    { label: "Angola", value: "Angola" },
    { label: "Anguilla", value: "Anguilla" },
    { label: "Antigua And Barbuda", value: "Antigua And Barbuda" },
    { label: "Argentina", value: "Argentina" },
    { label: "Armenia", value: "Armenia" },
    { label: "Australia", value: "Australia" },
    { label: "Australian Territory", value: "Australian Territory" },
    { label: "Austria", value: "Austria" },
    { label: "Azerbaijan", value: "Azerbaijan" },

    { label: "Bahamas", value: "Bahamas" },
    { label: "Bahrain", value: "Bahrain" },
    { label: "Bangladesh", value: "Bangladesh" },
    { label: "Barbados", value: "Barbados" },
    { label: "Belarus", value: "Belarus" },
    { label: "Belgium", value: "Belgium" },
    { label: "Belize", value: "Belize" },
    { label: "Benin", value: "Benin" },
    { label: "Bermuda", value: "Bermuda" },
    { label: "Bhutan", value: "Bhutan" },
    { label: "Bolivia", value: "Bolivia" },
    { label: "Bosnia/Herzegovina", value: "Bosnia/Herzegovina" },
    { label: "Botswana", value: "Botswana" },
    { label: "Brazil", value: "Brazil" },
    { label: "British Virgin Islands", value: "British Virgin Islands" },
    { label: "Brunei", value: "Brunei" },
    { label: "Bulgaria", value: "Bulgaria" },
    { label: "Burkina Faso", value: "Burkina Faso" },
    { label: "Burundi", value: "Burundi" },

    { label: "Cambodia", value: "Cambodia" },
    { label: "Cameroon", value: "Cameroon" },
    { label: "Canada", value: "Canada" },
    { label: "Cape Verde Islands", value: "Cape Verde Islands" },
    { label: "Cayman Islands", value: "Cayman Islands" },
    { label: "Central African Republic", value: "Central African Republic" },
    { label: "Chad", value: "Chad" },
    { label: "Chile", value: "Chile" },
    { label: "China", value: "China" },
    { label: "Colombia", value: "Colombia" },
    { label: "Comoros", value: "Comoros" },
    {
      label: "Congo (DROC), Democratic Republic of",
      value: "Congo (DROC), Democratic Republic of",
    },
    { label: "Congo (ROC), Republic of", value: "Congo (ROC), Republic of" },
    { label: "Cook Islands", value: "Cook Islands" },
    { label: "Costa Rica", value: "Costa Rica" },
    { label: "Croatia", value: "Croatia" },
    { label: "Cuba", value: "Cuba" },
    { label: "Cyprus", value: "Cyprus" },

    { label: "Czech Republic", value: "Czech Republic" },

    { label: "Denmark", value: "Denmark" },
    { label: "Diego Garcia", value: "Diego Garcia" },
    { label: "Djibouti", value: "Djibouti" },
    { label: "Dominica", value: "Dominica" },
    { label: "Dominican Republic", value: "Dominican Republic" },

    { label: "Ecuador", value: "Ecuador" },
    { label: "Egypt", value: "Egypt" },
    { label: "El Salvador", value: "El Salvador" },
    { label: "Equatorial Guinea", value: "Equatorial Guinea" },
    { label: "Eritrea", value: "Eritrea" },
    { label: "Estonia", value: "Estonia" },
    { label: "Ethiopia", value: "Ethiopia" },

    { label: "Falkland Islands", value: "Falkland Islands" },
    { label: "Faroe Islands", value: "Faroe Islands" },
    { label: "Fiji", value: "Fiji" },
    { label: "Finland", value: "Finland" },
    { label: "France", value: "France" },
    { label: "French Guiana", value: "French Guiana" },

    { label: "Gabon", value: "Gabon" },
    { label: "Georgia", value: "Georgia" },
    { label: "Germany", value: "Germany" },
    { label: "Ghana", value: "Ghana" },
    { label: "Gibraltar", value: "Gibraltar" },
    { label: "Greece", value: "Greece" },
    { label: "Greenland", value: "Greenland" },
    { label: "Grenada", value: "Grenada" },
    {
      label: "Guadeloupe (Fr. W. Indies)",
      value: "Guadeloupe (Fr. W. Indies)",
    },
    { label: "Guatemala", value: "Guatemala" },
    { label: "Guernsey", value: "Guernsey" },
    { label: "Guinea", value: "Guinea" },
    { label: "Guinea-Bissau", value: "Guinea-Bissau" },
    { label: "Guyana", value: "Guyana" },

    { label: "Haiti", value: "Haiti" },
    { label: "Honduras", value: "Honduras" },
    { label: "Hong Kong", value: "Hong Kong" },
    { label: "Hungary", value: "Hungary" },

    { label: "Iceland", value: "Iceland" },
    { label: "India", value: "India" },
    { label: "Indonesia", value: "Indonesia" },
    { label: "Iran", value: "Iran" },
    { label: "Iraq", value: "Iraq" },
    { label: "Ireland", value: "Ireland" },
    { label: "Isle of Man	", value: "Isle of Man" },
    { label: "Israel", value: "Israel" },
    { label: "Italy", value: "Italy" },
    { label: "Ivory Coast", value: "Ivory Coast" },

    { label: "Jamaica", value: "Jamaica" },
    { label: "Japan", value: "Japan" },
    { label: "Jersey", value: "Jersey" },
    { label: "Jordan", value: "Jordan" },

    { label: "Kazakhstan", value: "Kazakhstan" },
    { label: "Kenya", value: "Kenya" },
    { label: "Kiribati", value: "Kiribati" },
    { label: "Korea (North)", value: "Korea (North)" },
    { label: "Korea (South)", value: "Korea (South)" },
    { label: "Kuwait", value: "Kuwait" },
    { label: "Kyrgyzstan", value: "Kyrgyzstan" },

    { label: "Laos", value: "Laos" },
    { label: "Latvia", value: "Latvia" },
    { label: "Lebanon", value: "Lebanon" },
    { label: "Lesotho", value: "Lesotho" },
    { label: "Liberia", value: "Liberia" },
    { label: "Libya", value: "Libya" },
    { label: "Liechtenstein", value: "Liechtenstein" },
    { label: "Lithuania", value: "Lithuania" },
    { label: "Luxembourg", value: "Luxembourg" },

    { label: "Macau", value: "Macau" },
    { label: "Macedonia", value: "Macedonia" },
    { label: "Madagascar", value: "Madagascar" },
    { label: "Malawi", value: "Malawi" },
    { label: "Malaysia", value: "Malaysia" },
    { label: "Maldives", value: "Maldives" },
    { label: "Mali", value: "Mali" },
    { label: "Malta", value: "Malta" },
    { label: "Marshall Islands", value: "Marshall Islands" },
    { label: "Martinique", value: "Martinique" },
    { label: "Mauritania", value: "Mauritania" },
    { label: "Mauritius", value: "Mauritius" },
    { label: "Mexico", value: "Mexico" },
    { label: "Micronesia", value: "Micronesia" },
    { label: "Moldova", value: "Moldova" },
    { label: "Monaco", value: "Monaco" },
    { label: "Mongolia", value: "Mongolia" },
    { label: "Montenegro", value: "Montenegro" },
    { label: "Montserrat", value: "Montserrat" },
    { label: "Morocco", value: "Morocco" },
    { label: "Mozambique", value: "Mozambique" },
    { label: "Myanmar (Burma)", value: "Myanmar (Burma)" },

    { label: "Namibia", value: "Namibia" },
    { label: "Nauru", value: "Nauru" },
    { label: "Nepal", value: "Nepal" },
    { label: "Netherlands", value: "Netherlands" },
    {
      label: "Netherlands Antilles (Bonaire, Curacao, Saba, St. Eustis)",
      value: "Netherlands Antilles (Bonaire, Curacao, Saba, St. Eustis)",
    },
    { label: "New Caledonia", value: "New Caledonia" },
    { label: "New Zealand", value: "New Zealand" },
    { label: "Nicaragua", value: "Nicaragua" },
    { label: "Niger", value: "Niger" },
    { label: "Nigeria", value: "Nigeria" },
    { label: "Northern Mariana Islands", value: "Northern Mariana Islands" },
    { label: "Norway", value: "Norway" },

    { label: "Oman", value: "Oman" },

    { label: "Pakistan", value: "Pakistan" },
    { label: "Palau", value: "Palau" },
    { label: "Palestinian Authority", value: "Palestinian Authority" },
    { label: "Panama", value: "Panama" },
    { label: "Papua New Guinea", value: "Papua New Guinea" },
    { label: "Paraguay", value: "Paraguay" },
    { label: "Peru", value: "Peru" },
    { label: "Philippines", value: "Philippines" },
    { label: "Poland", value: "Poland" },
    { label: "Portugal", value: "Portugal" },

    { label: "Qatar", value: "Qatar" },

    { label: "Reunion", value: "Reunion" },
    { label: "Romania", value: "Romania" },
    { label: "Russia", value: "Russia" },
    { label: "Rwanda", value: "Rwanda" },

    {
      label: "Saipan (Northern Mariana Islands)",
      value: "Saipan (Northern Mariana Islands)",
    },
    { label: "Samoa", value: "Samoa" },
    { label: "San Marino", value: "San Marino" },
    { label: "Sao Tome/Principe", value: "Sao Tome/Principe" },
    { label: "Saudi Arabia", value: "Saudi Arabia" },
    { label: "Senegal", value: "Senegal" },
    { label: "Serbia", value: "Serbia" },
    { label: "Seychelles", value: "Seychelles" },
    { label: "Sierra Leone", value: "Sierra Leone" },
    { label: "Singapore", value: "Singapore" },
    { label: "Sint Maarten", value: "Sint Maarten" },
    { label: "Slovakia", value: "Slovakia" },
    { label: "Slovenia", value: "Slovenia" },
    { label: "Solomon Islands", value: "Solomon Islands" },
    { label: "South Africa", value: "South Africa" },
    { label: "South Sudan", value: "South Sudan" },
    { label: "Spain", value: "Spain" },
    { label: "Sri Lanka", value: "Sri Lanka" },
    { label: "St Pierre/Miquelon", value: "St Pierre/Miquel" },
    { label: "St. Kitts & Nevis", value: "St. Kitts & Nevis" },
    { label: "St. Lucia", value: "St. Lucia" },
    { label: "St. Vincent/Grenadines", value: "St. Vincent/Grenadines" },
    { label: "Sudan", value: "Sudan" },
    { label: "Suriname", value: "Suriname" },
    { label: "Swaziland", value: "Swaziland" },
    { label: "Sweden", value: "Sweden" },
    { label: "Switzerland", value: "Switzerland" },
    { label: "Syria", value: "Syria" },

    { label: "Taiwan", value: "Taiwan" },
    { label: "Tajikistan", value: "Tajikistan" },
    { label: "Tanzania", value: "Tanzania" },
    { label: "Thailand", value: "Thailand" },
    { label: "Togo", value: "Togo" },
    { label: "Tokelau", value: "Tokelau" },
    { label: "Tonga", value: "Tonga" },
    { label: "Trinidad & Tobago", value: "Trinidad & Tobago" },
    { label: "Tunisia", value: "Tunisia" },
    { label: "Turkey", value: "Turkey" },
    { label: "Turkmenistan", value: "Turkmenistan" },
    { label: "Turks and Caicos Islands", value: "Turks and Caicos Islands" },
    { label: "Tuvalu", value: "Tuvalu" },

    { label: "Uganda", value: "Uganda" },
    { label: "Ukraine", value: "Ukraine" },
    { label: "United Arab Emirates", value: "United Arab Emirates" },
    { label: "United Kingdom", value: "United Kingdom" },
    { label: "Uruguay", value: "Uruguay" },
    { label: "Uzbekistan", value: "Uzbekistan" },
    { label: "United States", value: "United States" },
    { label: "Vanuatu", value: "Vanuatu" },
    { label: "Venezuela", value: "Venezuela" },
    { label: "Vietnam", value: "Vietnam" },

    { label: "Yemen", value: "Yemen" },

    { label: "Zambia", value: "Zambia" },
    { label: "Zimbabwe", value: "Zimbabwe" },
  ];

  // create and update event

  //basic tab
  static eventScopeList = [
    { label: "Public", value: "PUBLIC" },
    { label: "Private", value: "PRIVATE" },
    // { label: "SEMI_PRIVATE", value: "SEMI_PRIVATE" },
  ];

  //Challenge  tab
  static ChallengeTypeList = [
    { label: "SUGAR FREE", value: "SUGAR_FREE" },
    { label: "HYDRATION", value: "HYDRATION" },
    { label: "NO UNHEALTY EATING", value: "NO_UNHEALTY_EATING" },
  ];

  static eventParticipationTypeList = [
    {
      label: "Virtual",
      value: "VIRTUAL",
    },
    { label: "Physical", value: "PHYSICAL" },
    { label: "Both", value: "BOTH" },
  ];

  static participantTypeList = [
    {
      label: "Virtual",
      value: "REGULAR",
    },
    { label: "Physical", value: "REGULAR_PHYSICAL" },
    { label: "Invitee", value: "INVITEE" },
  ];
  static participationTypeList = [
    { label: "Physical", value: "REGULAR_PHYSICAL" },
    {
      label: "Virtual",
      value: "REGULAR",
    },
  ];
  // REGULAR, INVITEE, REGULAR_PHYSICAL

  static eventTypeList = [
    { label: "Single", value: "SINGLE" },
    { label: "Streak", value: "STREAK" },
    // { label: "DUATHLON", value: "DUATHLON" },
    // { label: "TRIATHLON", value: "TRIATHLON" },
    { label: "Challenge", value: "CHALLENGE" },
    { label: "Training Program", value: "TRAINING" },
  ];

  static challengeTypeList = [
    { label: "Team", value: "TEAM" },
    { label: "Individual", value: "INDIVIDUAL" },
    { label: "Both", value: "BOTH" },
    { label: "Relay", value: "RELAY" },
    { label: "Team Relay", value: "TEAM_RELAY" },
    { label: "Cooper Test", value: "COOPER_TEST" },
    { label: "Stair Stepper", value: "STAIR_STEPPER" },
    { label: "Hydration Challenge", value: "HYDRATION" },
    { label: "Zero Sugar Challenge", value: "ZERO_SUGAR" },
    { label: "Yoga Challenge", value: "YOGA" }
  ];

  static challengeParameterList = [
    { label: "Distance", value: "DISTANCE" },
    { label: "Steps", value: "STEPS" },
    { label: "Calorie", value: "CALORIE" },
    { label: "Elevation", value: "ELEVATION" },
    { label: "Time", value: "TIME" },
    { label: "Wellness points", value: "WELLNESS_POINT" },
  ];
  static RunCycleChallengeParamterList = [
    { label: "Distance", value: "DISTANCE" },
    { label: "Calorie", value: "CALORIE" },
    { label: "Elevation", value: "ELEVATION" },
    { label: "Time", value: "TIME" },
    { label: "Wellness points", value: "WELLNESS_POINT" },
  ];

  static StepChallengeParamterList = [
    { label: "Steps", value: "STEPS" },
    { label: "Calorie", value: "CALORIE" },
    { label: "Elevation", value: "ELEVATION" },
    { label: "Time", value: "TIME" },
    { label: "Wellness points", value: "WELLNESS_POINT" },
  ];

  static activityTypeList = [
    { id: 1, label: "Run", value: "RUN", title: "RUN" },
    { id: 2, label: "Cycle", value: "CYCLE", title: "CYCLE" },
    // { id: 3, label: "Duathlon", value: "DUATHLON", title: "DUATHLON" },
    { id: 4, label: "Steps", value: "STEPS", title: "STEPS" },
    { id: 5, label: "Other", value: "ANY", title: "ANY" },
  ];

  static challengeGoal = [
    { label: "Daily Target", value: "DAILY_TARGET" },
    { label: "Total Target", value: "TOTAL_TARGET" },
  ];
  static eventItemsList = [
    {
      id: 1,
      label: "T-Shirt",
      value: "T-Shirt",
      title: "T-Shirt",
      maxQuantity: 1,
    },
    {
      id: 2,
      label: "Medal",
      value: "Medal",
      title: "Medal",
      maxQuantity: 1,
    },
  ];

  static femaleTShirtSizeList = [
    { id: 1, label: "XS", value: "XS" },
    { id: 2, label: "S", value: "S" },
    { id: 3, label: "M", value: "M" },
    { id: 4, label: "L", value: "L" },
    { id: 5, label: "XL", value: "XL" },
    { id: 6, label: "XXL", value: "XXL" },
    { id: 7, label: "XXS", value: "XXS" },
    { id: 8, label: "XSS", value: "XSS" },
  ];
  static bloodGroupList = [
    { label: "O+", value: "O+" },
    { label: "A+", value: "A+" },
    { label: "B+", value: "B+" },
    { label: "AB+", value: "AB+" },
    { label: "O-", value: "O-" },
    { label: "A-", value: "A-" },
    { label: "B-", value: "B-" },
    { label: "AB-", value: "AB-" },
  ];
  static itemTypeList = [
    { id: 1, label: "Paid", value: "EXPLICITE" },
    { id: 2, label: "Free", value: "IMPLICITE" },
  ];

  static notificationModeOptionList = [
    {
      // id: 1,
      label: "Email",
      value: "EMAIL",
    },
    {
      // id: 2,
      label: "Sms",
      value: "SMS",
    },
  ];

  //settings tab
  static timeValidationOptionList = [
    {
      id: 1,
      label: "Mandatory",
      value: "MANDATORY",
      title: "MANDATORY",
    },
    {
      id: 2,
      label: "Optional",
      value: "OPTIONAL",
      title: "OPTIONAL",
    },
    {
      id: 3,
      label: "Hide",
      value: "HIDE",
      title: "HIDE",
    },
  ];

  static CategoryListQuestions = [
    { id: 2, label: "Text", value: "Text" },
    { id: 1, label: "Range", value: "Range" },
    { id: 1, label: "Input number", value: "Input_number" },
  ];

  static computeTimeFieldList = [
    {
      id: 1,
      label: "Elapsed Time",
      value: "ELAPSED_TIME",
    },
    {
      id: 2,
      label: "Moving Time",
      value: "MOVING_TIME",
    },
  ];

  static distanceFieldList = [
    {
      id: 1,
      label: "Registered Distance",
      value: "REGISTERED_DISTANCE",
    },
    {
      id: 2,
      label: "Actual Distance",
      value: "ACTUAL_DISTANCE",
    },
  ];

  static invalidActivityCertificateTypeList = [
    {
      id: 1,
      label: "Participation Certificate",
      value: "PARTICIPATION_CERTIFICATE",
    },
    {
      id: 2,
      label: "Timing Certificate",
      value: "TIMING_CERTIFICATE",
    },
  ];

  static challengeDurationOptionsList = [
    { id: 1, label: "1 Week", value: "7" },
    { id: 2, label: "2 Week", value: "14" },
    { id: 3, label: "3 Week", value: "21" },
    { id: 4, label: "4 Week", value: "28" },
    { id: 5, label: "100 Days", value: "100" },
    { id: 6, label: "Custom days", value: "CUSTOM" },
  ];

  static deliverableTypes = [
    { label: "Generate Certificate", value: "CERTIFICATE" },
    { label: "Generate Bib", value: "BIB" },
    { label: "Generate Collage", value: "COLLAGE" },
    { label: "Generate Video", value: "VIDEO" },
  ];
  static participantDeliverableTypes = [
    { label: "Generate Participation Certificate", value: "CERTIFICATE" },
    { label: "Generate Bib", value: "BIB" },
    // { label: "Generate Consent", value: "CONSENT" },
  ];
  static trainingActivityTypeList = [
    { id: 1, label: "Cycling", value: "Cycling" },
    { id: 2, label: "Trekking", value: "Trekking" },
    { id: 3, label: "Swimming", value: "Swimming" },
    { id: 4, label: "Run", value: "Run" },
    { id: 5, label: "Yoga", value: "Yoga" },
    { id: 6, label: "Workout", value: "Workout" },
    { id: 7, label: "Steps", value: "Steps" },
    { id: 7, label: "Other", value: "Other" },
  ];
  static executionListFilters = [
    {
      id: 1,
      label: "Activity approval status",
      items: [
        {
          //consider these obj a FIELD
          id: 101,
          label: "All",

          value: "all",
        },
        {
          id: 102,
          label: "Approved",

          value: "approve",
        },
        {
          id: 103,
          label: "Not Approved",

          value: "notApprove",
        },
      ],
      filterType: "Approval",
      selectedValue: null,
      btnType: "RADIO",
      viewType: "ActivityList",
    },
    {
      id: 2,
      label: "Activity validity status",
      items: [
        {
          id: 104,
          label: "All",

          value: "allValid",
        },
        {
          id: 105,
          label: "Valid",

          value: "valid",
        },
        {
          id: 106,
          label: "Invalid",

          value: "notValid",
        },
      ],
      filterType: "Validity",
      selectedValue: null,
      btnType: "RADIO",
      viewType: "ActivityList",
    },
    // {
    //   id: 3,
    //   label: "All activity types",
    //   items: [
    //     {
    //       id: "107",
    //     },
    //   ],
    //   filterType: "Activity",
    //   btnType: "CHECKBOX",
    //   selectedValue: null,
    //   viewType: "ActivityList",
    // },
    {
      id: 3,
      label: "Consent status",
      items: [
        {
          id: "108",
        },
      ],
      filterType: "consent",
      btnType: "CHECKBOX",
      selectedValue: null,
      viewType: "ActivityList",
    },
  ];
  static atheleteListFilters = [
    {
      id: 1,
      label: "Activity recieved",
      items: [
        {
          id: 101,
          label: "All",

          value: "allActivityRecieve",
        },
        {
          id: 102,
          label: "Activity recieved",

          value: "activityRecieve",
        },
        {
          id: 103,
          label: "Activity not recieved",

          value: "activityNotRecieve",
        },
      ],
      filterType: "Recieve",
      selectedValue: null,
      btnType: "RADIO",
      viewType: "AthleteList",
    },
    {
      id: 2,
      label: "Attended",
      items: [
        {
          id: 104,
          label: "All",

          value: "allAttendedNotAttended",
        },
        {
          id: 105,
          label: "Attended",

          value: "attended",
        },
        {
          id: 106,
          label: "Not attended",

          value: "notAttended",
        },
      ],
      filterType: "Attend",
      selectedValue: null,
      btnType: "RADIO",
      viewType: "AthleteList",
    },
    {
      id: 3,
      label: "Certificate availability",
      items: [
        {
          id: 107,
          label: "All",

          value: "allCertificate",
        },
        {
          id: 108,
          label: "Certificate generated",

          value: "certificateGenerate",
        },
        {
          id: 109,
          label: "Certificate not generated",

          value: "certificateNotGenerate",
        },
      ],
      filterType: "Certificate",
      selectedValue: null,
      btnType: "RADIO",
      viewType: "AthleteList",
    },
    {
      id: 4,
      label: "Collage availability",
      items: [
        {
          id: 110,
          label: "All",

          value: "allCollage",
        },
        {
          id: 111,
          label: "Collage generated",

          value: "collageGenerate",
        },
        {
          id: 112,
          label: "Collage not generated",

          value: "collageNotGenerate",
        },
      ],
      filterType: "Collage",
      selectedValue: null,
      btnType: "RADIO",
      viewType: "AthleteList",
    },
    {
      id: 5,
      label: "Video availability",
      items: [
        {
          id: 113,
          label: "All",

          value: "allVideo",
        },
        {
          id: 114,
          label: "Video generated",

          value: "videoGenerate",
        },
        {
          id: 115,
          label: "Video not generated",

          value: "videoNotGenerate",
        },
      ],
      filterType: "Video",
      selectedValue: null,
      btnType: "RADIO",
      viewType: "AthleteList",
    },
    {
      id: 6,
      label: "Bib generated",
      items: [
        {
          id: 116,
          label: "All",

          value: "allBib",
        },
        {
          id: 117,
          label: "Bib generated",

          value: "bibGenerated",
        },
        {
          id: 118,
          label: "Bib not generated",

          value: "bibNotGenerated",
        },
      ],
      filterType: "bib",
      selectedValue: null,
      btnType: "RADIO",
      viewType: "AthleteList",
    },
    {
      id: 7,
      label: "Activity validity status",
      items: [
        {
          id: 119,
          label: "All",

          value: "allValid",
        },
        {
          id: 120,
          label: "Valid",

          value: "valid",
        },
        {
          id: 121,
          label: "Invalid",
          value: "notValid",
        },
      ],
      filterType: "validity",
      selectedValue: null,
      btnType: "RADIO",
      viewType: "AthleteList",
    },
    {
      id: 8,
      label: "Consent status",
      items: [
        {
          id: "122",
        },
      ],
      filterType: "consent",
      btnType: "CHECKBOX",
      selectedValue: null,
      viewType: "AthleteList",
    },
  ];
  // static activityListFilters = [
  //   {
  //     id: "1",
  //     label: "All activity types",
  //     btnType: "CHECKBOX",
  //     items: [
  //       {
  //         id: "101",
  //         displayName: "",

  //         type: "",
  //       },
  //     ],
  //     filterType: "Activity",
  //     selectedValue: null,
  //     viewType: "AthleteList",

  //   },
  // ];
  // static activityRecievedFilter =[{
  //   id:1,
  //   label:"Activity recieved",
  //   items:[{
  //     id: 101,
  //     label: "All",
  //     btnType: "RADIO",
  //     value: "all",
  //   },
  //   {
  //     id: 102,
  //     label: "Activity recieved",
  //     btnType: "RADIO",
  //     value: "activityRecieve",
  //   },
  //   {
  //     id: 103,
  //     label: "Activity not recieved",
  //     btnType: "RADIO",
  //     value: "activityNotRecieve",
  //   },
  // ]
  // }]
  // participants list filter
  static registrationListFilters = [
    {
      id: 1,
      label: "Consent status",
      items: [
        {
          //consider these obj a FIELD
          id: 101,
          label: "All",

          value: "all",
        },
        {
          id: 102,
          label: "Consent provided",

          value: "consentProvided",
        },
        {
          id: 103,
          label: "Consent not provided",

          value: "consentNotProvided",
        },
      ],
      filterType: "Consent",
      selectedValue: null,
      btnType: "RADIO",
      // viewType:"ActivityList"
    },
    {
      id: 2,
      label: "Bib generated",
      items: [
        {
          //consider these obj a FIELD
          id: 104,
          label: "All",

          value: "all",
        },
        {
          id: 105,
          label: "Bib generated",

          value: "bibGenerated",
        },
        {
          id: 106,
          label: "Bib not generated",

          value: "bibNotGenerated",
        },
      ],
      filterType: "bib",
      selectedValue: null,
      btnType: "RADIO",
      // viewType:"ActivityList"
    },
    {
      id: 3,
      label: "Registration status",
      items: [
        {
          //consider these obj a FIELD
          id: 107,
          label: "All",

          value: "all",
        },
        {
          id: 108,
          label: "Online",

          value: "online",
        },
        {
          id: 109,
          label: "Offline",

          value: "offline",
        },
      ],
      filterType: "register",
      selectedValue: null,
      btnType: "RADIO",
      // viewType:"ActivityList"
    },
    {
      id: 4,
      label: "Group",
      items: [
        {
          //consider these obj a FIELD
          id: 110,
          label: "All",

          value: "all",
        },
        {
          id: 111,
          label: "Participants",

          value: "participants",
        },
        {
          id: 112,
          label: "Volunteer",

          value: "volunteer",
        },
      ],
      filterType: "group",
      selectedValue: "participants",
      btnType: "RADIO",
      // viewType:"ActivityList"
    },
  ];

  static programChallengeAtheleteListFilters = [
    {
      id: 1,
      label: "Response recieved",
      items: [
        {
          id: 101,
          label: "All",

          value: "allResponseRecieve",
        },
        {
          id: 102,
          label: "Response recieved",

          value: "responseRecieve",
        },
        {
          id: 103,
          label: "Response not recieved",

          value: "responseNotRecieve",
        },
      ],
      filterType: "Recieve",
      selectedValue: "responseRecieve",
      btnType: "RADIO",
      viewType: "AthleteList",
    },
  ];

  static mockDataNoSugar = [
    {
      id: 18,
      question: "Were you able to continue the Zero Sugar challenge yesterday?",
      programChallengeType: "ZERO_SUGAR",
      questionResponseType: {
        id: 1,
        categoryType: "Text",
      },
      options: ["Yes", "No"],
      expectedOption: ["Yes"],
      programChallengeQuestionDto: {
        id: 26,
        questionId: 18,
        programChallengeId: 97,
        viewDate: "10-05-2024 00:00:00",
        tipId: 20,
      },
      isChallengePerformed: false,
    },
    {
      id: 18,
      question: "Were you able to continue the Zero Sugar challenge yesterday?",
      programChallengeType: "ZERO_SUGAR",
      questionResponseType: {
        id: 1,
        categoryType: "Text",
      },
      options: ["Yes", "No"],
      expectedOption: ["Yes"],
      programChallengeQuestionDto: {
        id: 44,
        questionId: 18,
        programChallengeId: 97,
        viewDate: "16-05-2024 00:00:00",
        tipId: 9,
      },
      isChallengePerformed: false,
    },
    {
      id: 18,
      question: "Were you able to continue the Zero Sugar challenge yesterday?",
      programChallengeType: "ZERO_SUGAR",
      questionResponseType: {
        id: 1,
        categoryType: "Text",
      },
      options: ["Yes", "No"],
      expectedOption: ["Yes"],
      programChallengeQuestionDto: {
        id: 31,
        questionId: 18,
        programChallengeId: 97,
        viewDate: "15-05-2024 00:00:00",
        tipId: 19,
      },
      response: "Yes",
      isChallengePerformed: true,
      responseStatus: "Positive",
    },
    {
      id: 18,
      question: "Were you able to continue the Zero Sugar challenge yesterday?",
      programChallengeType: "ZERO_SUGAR",
      questionResponseType: {
        id: 1,
        categoryType: "Text",
      },
      options: ["Yes", "No"],
      expectedOption: ["Yes"],
      programChallengeQuestionDto: {
        id: 30,
        questionId: 18,
        programChallengeId: 97,
        viewDate: "14-05-2024 00:00:00",
        tipId: 20,
      },
      response: "No",
      isChallengePerformed: true,
      responseStatus: "Negetive",
    },
    {
      id: 18,
      question: "Were you able to continue the Zero Sugar challenge yesterday?",
      programChallengeType: "ZERO_SUGAR",
      questionResponseType: {
        id: 1,
        categoryType: "Text",
      },
      options: ["Yes", "No"],
      expectedOption: ["Yes"],
      programChallengeQuestionDto: {
        id: 29,
        questionId: 18,
        programChallengeId: 97,
        viewDate: "13-05-2024 00:00:00",
        tipId: 19,
      },
      response: "Yes",
      isChallengePerformed: true,
      responseStatus: "Positive",
    },
    {
      id: 18,
      question: "Were you able to continue the Zero Sugar challenge yesterday?",
      programChallengeType: "ZERO_SUGAR",
      questionResponseType: {
        id: 1,
        categoryType: "Text",
      },
      options: ["Yes", "No"],
      expectedOption: ["Yes"],
      programChallengeQuestionDto: {
        id: 28,
        questionId: 18,
        programChallengeId: 97,
        viewDate: "12-05-2024 00:00:00",
        tipId: 20,
      },
      isChallengePerformed: false,
    },
    {
      id: 18,
      question: "Were you able to continue the Zero Sugar challenge yesterday?",
      programChallengeType: "ZERO_SUGAR",
      questionResponseType: {
        id: 1,
        categoryType: "Text",
      },
      options: ["Yes", "No"],
      expectedOption: ["Yes"],
      programChallengeQuestionDto: {
        id: 27,
        questionId: 18,
        programChallengeId: 97,
        viewDate: "11-05-2024 00:00:00",
        tipId: 19,
      },
      isChallengePerformed: false,
    },
    {
      id: 55,
      question: "Were you able to sustain Zero sugar challenge on Day 1",
      programChallengeType: "ZERO_SUGAR",
      questionResponseType: {
        id: 1,
        categoryType: "Text",
      },
      options: ["Yes", "No"],
      expectedOption: ["Yes"],
      programChallengeQuestionDto: {
        id: 25,
        questionId: 55,
        programChallengeId: 97,
        viewDate: "09-05-2024 00:00:00",
        tipId: 19,
      },
      response: "Yes",
      isChallengePerformed: true,
      responseStatus: "Positive",
    },
  ];
  static medicalConditionList = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  static climateList = [
    { label: "Temperate", value: "TEMPERATE" },
    { label: "Cold", value: "COLD" },
    { label: "Tropical", value: "TROPICAL" },
  ];
  static climateActivityList = [
    { label: "Sedentary", value: "SEDENTARY" },
    { label: "Light Activity", value: "LIGHT_ACTIVITY" },
    { label: "Moderately Active", value: "MODERATELY_ACTIVE" },
    { label: "Very Active", value: "VERY_ACTIVE" },
    { label: "Extremely Active", value: "EXTREMELY_ACTIVE" },
  ];
  static departmentList = [
    { label: "QA Department", value: "qa" },
    { label: "Development Department", value: "development" },
    { label: "Marketing Department", value: "marketing" },
    { label: "Designing Department", value: "designing" },
  ];

  static limitRowsArrayTeamAndIndividual = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "30", value: 30 },
  ];
  static hideMedal_RanksEventIds_QA = [2228];
  static hideMedal_RanksEventIds_PROD = [574];
  static cohortBasedLeaderBoard_QA = [2228];
  static cohortBasedLeaderBoard_PROD = [574];
  static challengeLabel_Location_Prod = [623];
  static challengeLabel_Location_QA = [2473];
  static challengeLabel_Corporate_QA = [507,508,515];



  static prod_url = "https://events.necessarydevil.com/";
}

export default ApplicationConstants;
