import React from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import Properties from "../../../properties/Properties";
import AppToolBar from "../../common/AppToolBar";
import { Grid, Badge, Avatar } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { CustomTextField } from "../../common/CustomTextField";
import MultiSelect from "../../common/MultiSelect";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { DeleteTrashIcon, IconCalendarBlue } from "../../common/SvgIcon";
import SubmitCancelBtn from "../../common/SubmitCancelBtn";
import ApplicationConstants from "../../../constants/ApplicationConstants";
import { validateEmail, validatePassword } from "../../../utils/Validator";
import { PhotoCamera } from "@material-ui/icons";
import {
  registerEvent,
  activateUserEmail,
  preRegisteration,
  getEventDetailsWithDistkey,
  checkEmailExists,
  getDecodedUrl,
  getProgramDetailsWithDistkey,
} from "../../../apis/eventService";
import { withRouter } from "react-router-dom";

import getRunnerGroupList from "./../../../apis/runnerGroupService";
import { isBrowser, isMobile } from "react-device-detect";
import RegistrationUtil from "./RegistrationUtil";
import DialogBox from "../../common/DialogBox";
import Snackbar from "../../common/Snackbar";
import { CheckBox } from "@material-ui/icons";
import ApplicationUtil from "../../../utils/ApplicationUtil";
import {
  getPostRegistrationFields,
  signupGenerateOTP,
  signupVerifyOTP,
} from "./../../../apis/eventService";
import OtpInput from "react-otp-input";
import { format, isAfter, isBefore } from "date-fns";
import Header from "../../common/Header";
import { AttachmentIcon } from "./../../common/SvgIcon";
import { IconButton } from "@material-ui/core";
import ImageDialogBox from "../../common/ImageDialogBox";
import { connect } from "react-redux";
import {
  updateUserDetails,
  updateUserSelectedEvent,
  updateUserSelectedProgram,
} from "../../../redux/user";
import { countryList } from "../../../constants/CountryList";

//Styles(CSS)
import "./../../../styles/common.css";
const styles = (theme) => ({
  avatarSize: {
    // height:"auto",
    // width:"auto"
    objectFit: "contain",
    width: "55px !important",
    height: "55px !important",
  },
  mainPanel: {
    paddingRight: theme.rightSpacing,
    paddingLeft: theme.leftSpacing,
    marginTop: "60px",
  },
  datePicker: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
    border: "1px solid #08456C",
  },
  datePickerError: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
    border: "1px solid red",
  },
  calendarIconCss: {
    height: 22,
    width: 22,
  },
  numberInputCss: {
    "&[type=number]": { "-moz-appearance": "textfield" },
    "&::-webkit-outer-spin-button": { "-webkit-appearance": "none", margin: 0 },
    "&::-webkit-inner-spin-button": { "-webkit-appearance": "none", margin: 0 },
  },
  /* Firefox */
  numberInputCssMozilla: {
    "&input[type=number]": {
      "-moz-appearance": "textfield",
    },
  },
  gridStyling: {
    height: "75px",
    margin: "0px",
  },
});

var momentTimeZone = require("moment-timezone");

class RegistrationContainer extends React.Component {
  state = {
    userMail: "",
    genderList: [
      { label: "Male", value: "MALE" },
      { label: "Female", value: "FEMALE" },
      { label: "Other", value: "OTHER" },
    ],
    countryCodeList: countryList, //ApplicationConstants.CountryCodes,
    countryList: ApplicationConstants.countries,
    eventId: 0,
    firstName: "",
    lastName: "",
    lastNameError: false,
    gender: "",
    bloodGroup: "",
    dob: null,
    contactCode: countryList.find((x) => x.value === " 91"),
    bloodGroupList: ApplicationConstants.bloodGroupList,
    // countryList.filter((x) => x.code === "IN"),
    //countryList["IN"], //{ label: "+91(India)", value: "91" },
    contactNumber: "",
    zip: "",
    country: { label: "India", value: "India" },
    runnerGroup: "",
    otherGroup: false,
    email: "",
    password: "",
    confirmPassword: "",
    changeActivity: "",
    firstNameError: false,
    genderError: false,
    // bloodGroupError:false,
    dobError: false,
    zipError: false,
    countryError: false,
    emailError: false,
    passwordError: false,
    confirmError: false,
    contactNumberError: false,
    runnerGroupList: null,
    showRunnerGroupList: true,
    emailReadOnly: false,
    open: false,
    openWarning: false,
    dialogHeader: "Signup Successful",
    dailogMsg: "",
    groupDetails: null,
    CheckboxSelected: false,
    showCheckBoxField: false,
    showDOB: true,
    // pin code
    showPinCode: true,
    showPhone: true,
    showRunnerGroupList: true,
    runnerGroupCity: "",
    // selectedRunnerGroup:'pune city',
    showEmailRegistrationForm: true,
    showRegistrationForm: false,
    supportedDomainList: null,
    enableShowOTP: false,
    showSendOTPButton: true,
    validDomain: false,
    showFirstScreen: true,
    otpVerified: false,
    distKey: "",
    dobMandatoryOnSignup: true,
    eventScope: "",
    emailAndContactMandatoryOnRegistration: false,
    height: "",
    heightError: false,
    weight: "",
    weightError: false,
    showHeightWeightFields: false,
    byMobile: false,
    byEmail: false,
    supportEmailDomainError: false,
    showEmailField: false,
  };
  componentDidMount() {
    document.title = Properties.Page_Title_Signup;
    let user = this.props.user;
    let programDetails = ApplicationUtil.getRunnersProgramDetails();

    let points;
    navigator.geolocation.getCurrentPosition((position) => {
      points = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
    });
    this.setState({
      areaLatLng: points,
    });
    let search = window.location.href;
    let key = search.split("regKey=")[1];
    if (key) localStorage.setItem("key", key);

    let distKey = search.split("distKey=")[1];
    if (distKey) {
      this.setState({ distKey: distKey });
      localStorage.setItem("distKey", distKey);
    }
    if (key != undefined) {
      //signup with regKey
      preRegisteration(key).then((res) => {
        this.setState({
          showDOB: res.data.event.showDOB,
          showPhone: res.data.event.showPhone,
          showPinCode: res.data?.event?.showPincode,
          showRunnerGroupList: res.data.event.showRunnerGroup,
        });
        this.setState({ eventScope: res.data.event.eventScope });
        if (
          res.data &&
          (res.data.event.eventScope == "PRIVATE" ||
            res.data.event.eventScope == "SEMI_PRIVATE")
        ) {
          this.setState({
            showRunnerGroupList: false,
            emailReadOnly: true,
            showCheckBoxField: true,
          });
          this.props.updateUserSelectedEvent(res.data.event);

          //data population
          this.setState({
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            email: res.data.email,
            dob: res.data.dateOfBirth ? res.data.dateOfBirth : "",
            pincode: res.data?.showPincode,
            contactNumber: res.data.contactNumber,
          });
          this.state.countryCodeList.map((c) => {
            if (c.value == res.data.countryCode)
              this.setState({ contactCode: c });
          });

          this.state.countryList.map((c) => {
            if (c.value == res.data.country) this.setState({ country: c });
          });
          this.state.genderList.map((g) => {
            if (g.value == res.data.gender) {
              this.setState({ gender: g });
            }
          });
          this.state.bloodGroupList.map((b) => {
            if (b.value == res.data.bloodGroup) {
              this.setState({ bloodGroup: b });
            }
          });
        }
      });
    } else if (distKey) {
      //signup with distKey
      if (programDetails?.programStartDate) {
        getProgramDetailsWithDistkey(distKey).then(({ data }) => {
          this.setState({
            showDOB: data?.showDOB,
            showPinCode: data?.showPincode,
            dobMandatoryOnSignup: data?.dobMandatoryOnSignup,
            emailAndContactMandatoryOnRegistration:
              data?.emailAndContactMandatoryOnRegistration,
            showPhone: data?.showPhone,
            showRunnerGroupList: data?.showRunnerGroup,
            supportedDomainList: data?.eventSupportedDomains,
            showHeightWeightFields: data?.showHtWtOnSignup,
            isProgram: true,
            programId: data?.id,
          });

          this.props.updateUserSelectedProgram({
            id: data.id,
            ...data,
          });
          //data population
        });
      } else {
        getEventDetailsWithDistkey(distKey).then((res) => {
          this.setState({
            showDOB: res.data.showDOB,
            showPinCode: res.data?.showPincode,
            dobMandatoryOnSignup: res.data.dobMandatoryOnSignup,
            emailAndContactMandatoryOnRegistration:
              res.data.emailAndContactMandatoryOnRegistration,
            showPhone: res.data.showPhone,
            showRunnerGroupList: res.data.showRunnerGroup,
            supportedDomainList: res.data.eventSupportedDomains,
            showHeightWeightFields: res.data.showHtWtOnSignup, //showHeightWeight,
            eventScope: res.data.eventScope,
            eventId: res.data?.id,
            showEmailField: res.data?.showEmailFieldOnLogin,
          });
          if (
            res.data &&
            (res.data.eventScope == "PRIVATE" ||
              res.data.eventScope == "SEMI_PRIVATE")
          ) {
            this.setState({
              emailReadOnly: false,
              showCheckBoxField: true,
            });

            //data population
          }
          this.props.updateUserSelectedEvent(res.data);
          this.props.updateUserSelectedProgram({});
        });
      }
    }

    // if distkey is not present means == normal login
    if (!distKey || programDetails) {
      this.setState({
        showEmailField: true,
      });
    }
    //       getRunnerGroupList().then((res) => {

    //         let runnerData = res.data?.data?.map((x) => {
    //           if(x?.city){
    //           x.city =   x.city.toLowerCase()
    //           }
    //           if(x?.groupVisibility){

    //             return x
    //           }
    //         })

    //         // logic for grouping runner groups based on city

    //         let runnerGroupWithCity = runnerData.filter((x) => x?.city).map((a)=>{
    //           a.label = a?.name
    //           a.value = `${a?.name} ${a?.city.toUpperCase()}`
    //           return a
    //         })
    //         let runnerGroupWithOther = runnerData.filter((x) => !x?.city).map((a)=>{

    //           a.label = a?.name
    //           a.value = a?.name
    //           return a
    //         })

    //         let cityList = ['Custom']
    //         let city;
    //         runnerGroupWithCity.forEach((item)=>{
    //           if(city !== item.city && !cityList.includes(item.city)){
    //             cityList.push(item.city)
    //             city = item.city
    //           }

    //         })

    //         if(runnerGroupWithOther?.length > 0){
    //           cityList.push('Others')
    //         }
    //         // cityList.push('Custom')

    //         let list = []
    //          cityList.forEach((item, index)=>{
    //           let obj = {
    //             id:index+1,
    //             // label : `${item.charAt(0).toUpperCase()}${item.substring(1)}`,
    //             label:item,
    //             options :item === 'Custom' ? [{label:'Other'}]: item === 'Others' ? runnerGroupWithOther : runnerGroupWithCity.filter((x) => x.city === item)
    //           }
    //           list.push(obj)

    //          })
    // console.log(list)

    //         // res.data.data.map((r) => ((r.label = r.name), (r.id = r.id)));

    //         // let newdata = { label: "Other" };
    //         // res.data.data.push(newdata);
    //         this.setState({ runnerGroupList: list });
    //       });

    let newUserDetails = JSON.parse(localStorage.getItem("newUserDetails"));

    if (newUserDetails?.showSignupForm) {
      this.setState({
        showEmailRegistrationForm: false,
        showRegistrationForm: true,
        showOTPForm: false,
        showSendOTPFields: false,
        otpVerified: true,
        email: newUserDetails?.byEmail ? newUserDetails?.email : "",
        contactNumber: newUserDetails?.byMobile
          ? newUserDetails?.contactNumber
          : "",
        byEmail: newUserDetails?.byEmail,
        byMobile: newUserDetails?.byMobile,
      });
    }
  }

  getSuccessDialogComponent = () => {
    return (
      <div>
        {/*
        <Typography style={{ display: "grid" }}>

          <div>
            <span style={{ color: "#D85656" }}>
              Note:
              <div>Your email verification is pending.</div>
            </span>
            <span>
              We have sent verification email to{" "}
              <span style={{ color: "#2883B5" }}>{this.state.userMail}</span>.
              Please verify before next log-in.
            </span>
          </div>

      </Typography>*/}
      </div>
    );
  };
  getDialogComponent = () => {
    return (
      <div>
        <Typography style={{ display: "grid" }}>
          <span style={{ color: "#D85656" }}>
            <div>{this.state.changeActivity}</div>
          </span>
        </Typography>
      </div>
    );
  };
  closeWindow = () => {
    this.setState({ open: false });
  };

  closeWindowWarning = () => {
    this.setState({ openWarning: false });
  };

  saveFields = () => {
    const {
      firstName,
      lastName,
      gender,
      //dob,
      bloodGroup,
      contactCode,
      contactNumber,
      zip,
      country,
      runnerGroup,
      runnerGroupCity,
      email,
      password,
      groupDetails,
      height,
      weight,
      showHeightWeightFields,
      showDOB,
      showPinCode,
      dobMandatoryOnSignup,
      emailAndContactMandatoryOnRegistration,
    } = this.state;

    let customParams = {
      firstName: firstName,
      lastName: lastName,
      // contactNumber: contactNumber,
      // password: password,
      gender: gender.value,
      // bloodGroup: bloodGroup.value,
      pincode: zip,
      country: country.value,
      otpVerified: this.state.otpVerified,
    };

    if (showDOB) {
      let exactDate;
      if (this.state.dob) {
        exactDate = format(new Date(this.state.dob), "dd-MM-yyyy");
        customParams.dob = exactDate;
      }
    }
    if (showHeightWeightFields) {
      customParams.height = height;
      customParams.weight = weight;
    }

    if (email !== "") {
      customParams.email = email;
    }

    if (contactNumber !== "") {
      customParams.contactNumber = contactNumber;
      customParams.countryCode = contactCode.value;
      // contactCode.value;
    }
    // if (!this.state.otherGroup) {
    //   //runnerGroup.map(r => ((r.id = r.id), (r.name = r.name)));
    //   if (this.state.groupDetails)
    //     customParams = {
    //       ...customParams,
    //       groupDetails: { id: groupDetails.id, name: groupDetails.name },
    //     };
    // } else if(runnerGroup || runnerGroupCity) {
    //   customParams = {
    //     ...customParams,
    //     groupDetails:{name:runnerGroup,city:runnerGroupCity, groupVisibility:false},
    //     runnerGroup: runnerGroup,
    //     runnerGroupCity:runnerGroupCity
    //   };
    // }

    if (this.state.showHeightWeightFields) {
      customParams.height = this.state.height;
      customParams.weight = this.state.weight;
    }
    let organizer = JSON.parse(localStorage.getItem("organizer"));
    if (organizer) {
      customParams.organizer = organizer;
    }
    if (
      this.state.eventScope == "PRIVATE" ||
      this.state.eventScope == "SEMI_PRIVATE"
    ) {
      //checkbox field
      customParams.showPublicEvents = this.state.CheckboxSelected;
    }

    let profilePicture = null;
    if (this.state.profilePicture) profilePicture = this.state.profilePicture;

    //passing regKey
    let key = localStorage.getItem("key");
    if (key != "undefined" && key != null) {
      customParams.regKey = key;
    }

    //passing distKey
    let distKey = localStorage.getItem("distKey");
    if (this.state.distKey != "") {
      customParams.distKey = this.state.distKey;
    }
    if (this.state.areaLatLng) {
      customParams.areaLatLng = this.state.areaLatLng;
    }
    //timezone
    const timeZone = momentTimeZone.tz.guess();
    if (timeZone) {
      customParams.timezone = timeZone;
    }

    registerEvent(customParams, profilePicture).then((res) => {
      // if (res.data.success && res.data.success.code == 409) {
      //   this.setState({
      //     changeActivity: res.data.success.verbose,
      //     openWarning: true,
      //   });
      // } else {
      RegistrationUtil.setToken(res.data.token);
      let newData = res.data;
      newData.runner = {
        id: res.data.runnerId,
      };
      this.props.updateUserDetails(res.data);

      localStorage.setItem("fromSignup", true);
      this.setState({ dailogMsg: "" });
      //this.getSuccessDialogComponent();
      if (this.state.distKey !== "") {
        this.setState({ open: false });
        this.navigateToEventList();
      } else {
        this.setState({ open: true });
      }

      //this.props.history.push("/eventRegistration");
      //this.props.history.push("/eventDetails");
      // }
    });
  };
  multipleUploadPost = (e) => {
    if (e.target.id == "profile-file") {
      if (
        Array.from(e.target.files)?.every(
          (file) => file?.type === "image/png" || file?.type === "image/jpeg"
        )
      ) {
        let files = e.target.files[0];
        if (files) {
          let imgsrc = URL.createObjectURL(files);
          this.setState({
            profilePicture: files,
            profilePicturePreview: imgsrc,
          });
        }
      } else {
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "only jpeg or png image type is allowed",
        });
      }
    }
  };
  fullImageView = (key) => {
    let Files = this.state.uploadedFiles.filter((file) => {
      if (file.key !== undefined && file.key !== key) return true;
      this.setState({ imageDialog: true, image_src: file.src });
    });
    //this.setState({preview:Files.src});
  };

  fullPostRunPicture() {
    let file = this.state.profilePicture;
    this.setState({
      imageDialog: true,
      image_src: this.state.profilePicturePreview,
    });
  }
  handleImageDelete = (event) => {
    this.setState({ profilePicture: null, profilePicturePreview: null });
  };
  handleCheckBoxChange = (event) => {
    this.setState({ CheckboxSelected: event.target.checked });
  };

  handleClose = () => {
    this.setState({ open: false, openWarning: false });
  };

  validatePasswordField = (key, value) => {
    let isValid = validatePassword(value);
    if (key == "password") {
      if (isValid) {
        this.setState({ password: value, passwordError: false });
      } else {
        this.setState({ passwordError: true, password: value });
      }
    } else {
      if (isValid && value == this.state.password) {
        this.setState({ confirmPassword: value, confirmError: false });
      } else {
        this.setState({ confirmError: true });
      }
    }
  };

  handleGenderChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      this.setState({
        gender: selectedValues,
        genderError: false,
      });
    } else {
      this.setState({
        gender: selectedValues,
        genderError: true,
      });
    }
  };
  // handleBloodGroupChange = (selectedValues) => {
  //   var arr = [];
  //   if (selectedValues != null) {
  //     arr.push(selectedValues.value);

  //     this.setState({
  //       bloodGroup: selectedValues,
  //     });
  //   }
  // };
  // handleRunnerGroupChange = (selectedValues) => {
  //   console.log(selectedValues)

  //     this.setState({selectedRunnerGroup:selectedValues, groupDetails:selectedValues})

  //       if (selectedValues?.label == "Other") this.setState({ otherGroup: true });
  //       else this.setState({ otherGroup: false });

  // };
  handleCodeChange = (selectedValues) => {
    if (selectedValues != null) {
      this.setState({
        contactCode: selectedValues,
      });
    } else {
      this.setState({
        contactCode: selectedValues,
      });
    }
  };

  handleCountryChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);
      this.setState({
        country: selectedValues,
        countryError: false,
      });

      this.state.countryCodeList.map((c) => {
        let newc = c;
        //let country = c.label.match(/\(([^)]+)\)/)[1]; //replace(/{([^{}]+)}/g); //replace(/[^a-zA-Z]/g);
        if (c.label) {
          let country = c.label.replace("+" + c.value, "").match(/\(([^)]+)\)/);
          country = country?.length ? country[1] : "";
          if (selectedValues.label == country) {
            //console.log(c);
            this.setState({ contactCode: newc });
          }
        }
      });
    } else {
      this.setState({
        country: selectedValues,
        countryError: true,
      });
    }
  };
  changeToDefaultScreen = () => {
    this.setState({
      showEmailRegistrationForm: true,
      otp: "",
      showSendOTPFields: false,
      enableShowOTP: false,
      showOTPForm: false,
      showRegistrationForm: false,
    });
  };
  //otp
  handleChange = (otp) => this.setState({ otp });

  handleSendOTP = () => {
    const { email = "", emailError = "" } = this.state;
    if (email == "" || emailError == true) {
      this.setState({ emailError: true });
    } else {
      let requestParams = {
        userName: email,
        // distKey: localStorage.getItem("distKey"),
      };

      if (this.state.distKey !== "") {
        if (this.state.isProgram) {
          requestParams.programDistKey = this.state.distKey;
        } else {
          requestParams.distKey = this.state.distKey;
        }
      }

      // requestParams;
      //api for signup with OTP generation
      signupGenerateOTP(requestParams).then((res) => {
        this.setState({
          showOTPForm: true,
          showSendOTPButton: false,
        });
      });
    }
  };

  handleResendOTP = () => {
    let requestParams = {
      userName: this.state.email,
    };

    if (this.state.distKey !== "") {
      if (this.state.isProgram) {
        requestParams.programDistKey = this.state.distKey;
      } else {
        requestParams.distKey = this.state.distKey;
      }
    }
    // requestParams;
    //api for signup with OTP generation
    signupGenerateOTP(requestParams).then((res) => {
      this.setState({
        otp: "",
        showOTPForm: true,
        showSendOTPButton: false,
      });
    });
  };

  handleOTPSubmit = () => {
    const { email, byMobile, byEmail } = this.state;
    let requestParams = {
      userName: this.state.email,
      otp: this.state.otp,
    };

    if (this.state.distKey != "") requestParams.distKey = this.state.distKey;

    //api for verifying OTP
    signupVerifyOTP(requestParams)
      .then((res) => {
        this.setState({
          showEmailRegistrationForm: false,
          showRegistrationForm: true,
          showOTPForm: false,
          showSendOTPFields: false,
          otpVerified: true,
          email: byEmail ? email : "",
          contactNumber: byMobile ? email : "",
        });
      })
      .catch((error) => {
        if (error?.data?.success?.code === "600") {
          this.setState({
            showEmailRegistrationForm: true,
            showRegistrationForm: false,
            showOTPForm: false,
            showSendOTPFields: false,
            otpVerified: false,
            otp: null,
            email: null,
          });
        }
      });
  };

  goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  cancelFields = () => {
    const { byEmail, byMobile, email, contactNumber, contactCode } = this.state;
    this.setState({
      firstName: "",
      lastName: "",
      gender: "",
      bloodGroup: "",
      dob: null,
      zip: "",
      country: "",
      runnerGroup: "",
      profilePicturePreview: null,
      profilePicture: null,
      password: "",
      confirmPassword: "",
      pincode: "",
      selectedRunnerGroup: null,
      email: byEmail ? email : "",
      contactNumber: byMobile ? contactNumber : "",
      contactCode: byMobile ? contactCode : "",
    });
  };
  // -------------------------------------------------------------------------
  ValidateFields = () => {
    const {
      firstName,
      // lastName,
      gender,
      bloodGroup,
      dob,
      showDOB,
      dobError,
      contactCode,
      contactNumber,
      zip,
      country,
      //runnerGroup,
      email,
      password,
      confirmPassword,
      // firstNameError,
      // genderError,
      pincode,
      zipError,
      // countryError,
      emailError,
      passwordError,
      confirmError,
      showHeightWeightFields,
      height,
      heightError,
      weight,
      weightError,
      byMobile,
      byEmail,
      firstNameError,
      lastName,
      lastNameError,
      dobMandatoryOnSignup,
      emailAndContactMandatoryOnRegistration,
      supportedDomainList,
      showPinCode,
    } = this.state;

    if (
      (supportedDomainList?.length > 0 ||
        emailAndContactMandatoryOnRegistration) &&
      (email == "" || emailError == true || !this.validateEmailDomain(email))
    ) {
      // if (
      //   email == "" ||

      //   emailError === true ||
      //   !this.validateEmailDomain(email)
      // ) {
      this.setState({ emailError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message:
          supportedDomainList?.length > 0 && !email
            ? "Please enter valid email address"
            : !this.validateEmailDomain(email)
            ? "Email domain is not supported for this event."
            : "Please enter valid email address",
      });
      this.goToTop();
    } else if (firstName == "" || firstNameError) {
      this.setState({ firstNameError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message:
          firstName && firstNameError
            ? "Invalid first name, no special characters and digits allowed"
            : "Please enter first name.",
      });
      this.goToTop();
    } else if (lastName && lastNameError) {
      this.setState({ lastNameError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message:
          lastName && lastNameError
            ? "Invalid last name, no special characters and digits allowed"
            : "Please enter last name.",
      });
      this.goToTop();
    } else if (gender == "") {
      this.setState({ genderError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select gender.",
      });
      this.goToTop();
    } else if (
      showDOB &&
      dobMandatoryOnSignup &&
      (dob === "" || dobError === true || dob === null)
    ) {
      this.setState({ dobError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select date of birth.",
      });
      this.goToTop();
    } else if (country == "") {
      this.setState({ countryError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select country.",
      });
      this.goToTop();
    } else if (showPinCode && zip == "") {
      this.setState({ zipError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter pincode.",
      });
      this.goToTop();
    } else if (
      emailAndContactMandatoryOnRegistration &&
      (contactNumber == "" ||
        !ApplicationUtil.validateContactNumber(contactNumber))
    ) {
      this.setState({ contactNumberError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter valid contact number.",
      });
      this.goToTop();
    } else if (
      !emailAndContactMandatoryOnRegistration &&
      this.state.contactNumberError &&
      (contactNumber == "" ||
        !ApplicationUtil.validateContactNumber(contactNumber))
    ) {
      this.setState({ contactNumberError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter valid contact number.",
      });
      this.goToTop();
    } else if (
      showHeightWeightFields &&
      (height === "" || heightError === true)
    ) {
      this.setState({ heightError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter height.",
      });
      this.goToTop();
    } else if (
      showHeightWeightFields &&
      (weight === "" || weightError === true)
    ) {
      this.setState({ weightError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter weight.",
      });
      this.goToTop();
    }
    // else if (password == "" || passwordError == true) {
    //   this.setState({ passwordError: true });
    //   Snackbar.show({
    //     variant: "error",
    //     isAutoHide: true,
    //     preventDuplicate: true,
    //     message: "Please enter valid password.",
    //   });
    //   this.goToTop();
    // } else if (
    //   confirmPassword == "" ||
    //   confirmPassword != password ||
    //   confirmError == true
    // ) {
    // this.setState({ confirmError: true });
    // if (confirmPassword != password) {
    //   Snackbar.show({
    //     variant: "error",
    //     isAutoHide: true,
    //     preventDuplicate: true,
    //     message: "Password and confirm password does not match.",
    //   });
    // } else {
    //   Snackbar.show({
    //     variant: "error",
    //     isAutoHide: true,
    //     preventDuplicate: true,
    //     message: "Please enter valid confirm password.",
    //   });
    // }
    //   this.goToTop();
    // }
    else {
      this.saveFields();
    }
  };
  // -------------------------------------------------------------------------
  isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
  };

  validateEmailDomain = (value) => {
    let domainValid = false;
    let domain = value.split("@");
    domain = domain[1]?.toLowerCase();

    if (
      this.state.supportedDomainList &&
      this.state.supportedDomainList.length > 0
    ) {
      this.state.supportedDomainList.map((e) => {
        // if (!domainValid) {
        if (e.validationDomain.toLowerCase() == domain) {
          domainValid = true;
          // } else {
          //   domainValid = false;
          // }
        }
      });
      return domainValid;
    } else {
      return true;
    }
  };

  validateEmailORContact = (value) => {
    let isNumber = /^\d+$/.test(value);

    if (isNumber) {
      if (ApplicationUtil.validateContactNumber(value)) {
        this.setState({
          email: value,
          emailError: false,
          contactNumber: value,
          contactNumberError: false,
          byMobile: true,
          byEmail: false,
        });
      } else {
        this.setState({
          emailError: true,
          email: value,
          contactNumber: value,
          contactNumberError: true,
        });
      }
    } else {
      let isValidEmail = validateEmail(value);
      if (isValidEmail) {
        this.setState({
          email: value,
          emailError: false,
          supportEmailDomainError: false,
          byEmail: true,
        });
      } else {
        this.setState({
          emailError: true,
          email: value,
        });
      }
    }
  };

  handleKeyDown = (event) => {
    if (event.key === ".") {
      event.preventDefault();
    }
  };

  validateContact = (value) => {
    let val = value.trim();
    let isNumber = /^\d+$/.test(val);

    if (ApplicationUtil.validateContactNumber(value)) {
      this.setState({
        email: value,
        emailError: false,
        contactNumber: value,
        contactNumberError: false,
        byMobile: true,
        byEmail: false,
      });
    } else {
      this.setState({
        emailError: true,
        email: value,
        contactNumber: value,
        contactNumberError: true,
      });
    }
  };

  validateEmailId = (value) => {
    let isValidEmail = validateEmail(value);

    if (isValidEmail || value === "") {
      this.setState({ email: value, emailError: false });
    } else {
      this.setState({ emailError: true, email: value });
    }
  };
  checkAlreadyRegisteredEmail = () => {
    const {
      email,
      emailError,
      byEmail,
      byMobile,
      supportedDomainList,
      showEmailField,
      contactNumber,
      contactNumberError,
    } = this.state;
    if ((email == "" || emailError == true) && showEmailField) {
      this.setState({ emailError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter valid email address or contact number.",
      });
      this.goToTop();
      return;
    } else if (
      (contactNumber === "" || contactNumberError === true) &&
      !showEmailField
    ) {
      this.setState({ contactNumberError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter valid  contact number.",
      });
      this.goToTop();
      return;
    } else if (byEmail && supportedDomainList && supportedDomainList != null) {
      let val = this.validateEmailDomain(email);
      if (!val) {
        this.setState({ supportEmailDomainError: true });
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Email domain is not supported for this event.",
        });
        return;
      }
    }
    if (byEmail) {
      this.setState({
        byMobile: false,
      });
    }

    //api for checking email is already registered on app
    checkEmailExists(this.state.email, byEmail, byMobile).then((res) => {
      if (res.data.success.code == 200) {
        if (this.state.distKey != "") {
          return this.props.history.push(`/?distKey=${this.state.distKey}`);
        } else return this.props.history.push("/login");
      } else if (res.data.success.code == 404) {
        this.setState({
          showEmailRegistrationForm: false,
          showSendOTPFields: true,
          enableShowOTP: true,
        });
      }
    });
  };

  navigate = () => {
    //window.location.assign("https://necessarydevil.com")

    this.props.history.push("/login");
  };

  navigateToEventList = () => {
    const { isProgram, programId, eventId, distKey } = this.state;
    if (distKey == null || distKey == "" || distKey == "undefined") {
      if (this.props.history.location?.encodedUrl) {
        getDecodedUrl(this.props.history.location?.encodedUrl).then((res) => {
          if (res.data.fullUrl) {
            if (res.data.fullUrl.includes("#")) {
              let newUrl = ApplicationUtil.getRedirectedRoute(res.data.fullUrl);
              this.props.history.push(`${newUrl}`);
            } else {
              this.props.history.push(`${res.data.fullUrl}`);
            }
          }
        });
      } else {
        this.props.history.push("/eventlist");
      }
    } else {
      let id = isProgram ? programId : eventId;
      getPostRegistrationFields(id).then((res) => {
        console.log(res);
        let d = res.data;
        if (d && d.length > 0) {
          d = d.filter(function (ele) {
            if (ele.formName == "SIGNUP") return true;
          });

          this.props.history.push("/postRegister");
        } else {
          this.props.history.push(
            isProgram ? "/programRegistration" : "/eventRegistration"
          );
        }
      });
    }
  };

  render() {
    const { classes } = this.props;
    const {
      // firstName,
      // lastName,
      // gender,
      dob,
      contactCode,
      //bloodGroupError,
      // contactNumber,
      // city,
      // zip,
      country,
      // runnerGroup,
      email,
      contactNumber,

      // password,
      // confirmPassword,
      firstNameError,
      genderError,
      // bloodGroupError,
      dobError,
      showDOB,
      zipError,
      countryError,
      emailError,
      passwordError,
      confirmError,
      contactNumberError,
      height,
      heightError,
      weight,
      weightError,
      byEmail,
      byMobile,
      lastNameError,
      runnerGroupList,
      runnerGroup,
      runnerGroupCity,
      dobMandatoryOnSignup,
      emailAndContactMandatoryOnRegistration,
      supportedDomainList,
      supportEmailDomainError,
      showEmailField,
    } = this.state;
    return (
      <React.Fragment>
        <AppToolBar
          backNavigate={() => this.props.history.goBack()}
          showBack={isBrowser ? false : true}
        >
          {/* <Header signup label={Properties.Label_Registration} /> */}
          <div
            style={{
              maxHeight: "60px",
              height: "60px",
              minHeight: "60px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div p={1} style={{ marginLeft: "20px" }}>
              <Typography className="header__typography" noWrap>
                {Properties.Label_Registration}
              </Typography>
            </div>
            {!this.state.showRegistrationForm && (
              <div
                style={{
                  marginRight: "10px",
                  //isBrowser ?
                  width: "150px", // : "160px",
                }}
                // className="header--user"
              >
                <MultiSelect
                  value={contactCode}
                  options={this.state.countryCodeList}
                  onChange={this.handleCodeChange}
                  showIcon={true}
                />
              </div>
            )}
          </div>
        </AppToolBar>
        <div className={classes.mainPanel}>
          {this.state.showEmailRegistrationForm && (
            <Grid
              style={{
                width: "100%",
                //height: "75px",
                margin: "0px",
                padding: "10px",
              }}
              container
              spacing={1}
              justifyContent="center"
            >
              {showEmailField ? (
                <Grid item xs={9}>
                  <Typography variant="body2" gutterBottom>
                    <span style={{ color: "#727272" }}>
                      {Properties.Label_Email}/ {Properties.Label_Mobile}
                    </span>
                    <span style={{ color: "red" }}>*</span>
                  </Typography>{" "}
                  <CustomTextField
                    style={{
                      border:
                        emailError || supportEmailDomainError
                          ? "1px solid red"
                          : "1px solid #08456C",
                      height: "45%",
                      maxHeight: "45%",
                      minHeight: "45%",
                      width: "100%",
                    }}
                    type={"text"}
                    keyToSet={"email"}
                    value={this.state.email}
                    placeholder={"Email or Mobile"}
                    handleChange={(prop) => (event) => {
                      this.validateEmailORContact(event.target.value);
                    }}
                  />
                </Grid>
              ) : (
                <Grid item xs={9}>
                  <Typography variant="body2" gutterBottom>
                    <span style={{ color: "#727272" }}>
                      {Properties.Label_Mobile}
                    </span>
                    <span style={{ color: "red" }}>*</span>
                  </Typography>{" "}
                  <CustomTextField
                    style={{
                      border: contactNumberError
                        ? "1px solid red"
                        : "1px solid #08456C",
                      height: "45%",
                      maxHeight: "45%",
                      minHeight: "45%",
                      width: "100%",
                    }}
                    type={"Number"}
                    keyToSet={"email"}
                    value={this.state.contactNumber}
                    placeholder={"Enter Mobile Number"}
                    onKeyDown={this.handleKeyDown}
                    handleChange={(prop) => (event) => {
                      this.validateContact(event.target.value);
                    }}
                  />
                </Grid>
              )}

              <Grid item xs={3} style={{ margin: "auto" }}>
                <SubmitCancelBtn
                  onClick={this.checkAlreadyRegisteredEmail}
                  label={" Check"}
                  type="submit"
                  style={{ width: "auto" }}
                />
                {/* <button onClick={this.checkAlreadyRegisteredEmail}>
                  Check
                </button> */}
              </Grid>
            </Grid>
          )}
          {/*change sendOTP & email readOnly   */}
          {this.state.showSendOTPFields && (
            <Grid
              style={{
                width: "100%",
                //height: "75px",
                margin: "0px",
              }}
              container
              spacing={1}
              justifyContent="center"
            >
              <Grid item xs={9}>
                <Typography variant="body2" gutterBottom>
                  <span style={{ color: "#727272" }}>
                    {Properties.Label_Email}
                  </span>
                  <span style={{ color: "red" }}>*</span>
                </Typography>{" "}
                <CustomTextField
                  style={{
                    border: "1px solid #08456C",
                    height: "45%",
                    maxHeight: "45%",
                    minHeight: "45%",
                    width: "100%",
                    backgroundColor: "#D1D5DB",
                  }}
                  type={"email"}
                  keyToSet={"email"}
                  value={this.state.email}
                  handleChange={(prop) => (event) => {
                    this.validateEmailORContact(event.target.value);
                  }}
                  readOnly={true}
                />
              </Grid>
              <Grid item xs={3} style={{ margin: "auto" }}>
                <SubmitCancelBtn
                  isDisabled={!this.state.enableShowOTP}
                  onClick={this.changeToDefaultScreen}
                  label={"Change"}
                  type="submit"
                  style={{ width: "auto" }}
                />
                {/* <button onClick={this.changeToDefaultScreen}>Change</button> */}
              </Grid>

              {!this.state.showOTPForm && (
                <Grid style={{ margin: "auto" }}>
                  <SubmitCancelBtn
                    isDisabled={!this.state.enableShowOTP}
                    onClick={this.handleSendOTP}
                    label={"Send OTP"}
                    type="submit"
                    style={{ width: "auto", marginTop: "3%" }}
                  />
                </Grid>
              )}
            </Grid>
          )}
          {this.state.showOTPForm && (
            <Grid
              style={{
                width: "100%",
                height: "75px",
                paddingTop: "10px",
                margin: "0px",
                padding: "10px",
              }}
              container
              spacing={1}
              justify="center"
            >
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Grid style={{ padding: "3% 0" }}>
                  <OtpInput
                    value={this.state.otp}
                    onChange={this.handleChange}
                    numInputs={6}
                    // separator={<span> </span>}
                    isInputNum={true}
                    inputStyle={{
                      border: 0,
                      borderBottom: "1px solid black",
                      marginRight: "10px",
                    }}
                    containerStyle={{ justifyContent: "center" }}
                  />
                </Grid>
                <Grid>
                  <button
                    style={{
                      fontSize: "11px",
                      height: "17px",
                      padding: "0px 0px 0px 0px",
                    }}
                    onClick={this.handleResendOTP}
                  >
                    Resend OTP
                  </button>
                </Grid>
                <Grid>
                  <SubmitCancelBtn
                    onClick={this.handleOTPSubmit}
                    label={"Submit"}
                    type="submit"
                    style={{ width: "auto", marginTop: "3%" }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}

          {this.state.showRegistrationForm && (
            <div style={{ padding: "10px" }}>
              {showEmailField && (
                <Grid spacing={1} container className={classes.gridStyling}>
                  <Grid item xs={12}>
                    <Typography variant="body2" gutterBottom>
                      <span style={{ color: "#727272" }}>
                        {Properties.Label_Email}
                      </span>
                      {(supportedDomainList?.length > 0 ||
                        emailAndContactMandatoryOnRegistration) &&
                        !byEmail && <span style={{ color: "red" }}>*</span>}
                    </Typography>{" "}
                    <CustomTextField
                      style={{
                        border: emailError
                          ? "1px solid red"
                          : "1px solid #08456C",
                        height: "45%",
                        maxHeight: "45%",
                        minHeight: "45%",
                        width: isBrowser ? "70%" : "97%",
                        backgroundColor: byEmail ? "#D1D5DB" : "",
                      }}
                      type={"email"}
                      keyToSet={"email"}
                      value={email}
                      handleChange={(prop) => (event) => {
                        this.validateEmailId(event.target.value);
                      }}
                      readOnly={byEmail ? true : false}
                    />
                  </Grid>
                </Grid>
              )}

              <Grid spacing={1} container className={classes.gridStyling}>
                <Grid item xs={6}>
                  <Typography variant="body2" gutterBottom>
                    <span style={{ color: "#727272" }}>
                      {Properties.Label_FirstName}
                    </span>
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <CustomTextField
                    style={
                      firstNameError
                        ? {
                            border: "1px solid red",
                            height: "45%",
                            maxHeight: "45%",
                            minHeight: "45%",
                            width: isBrowser ? "360px" : "160px",
                          }
                        : {
                            border: "1px solid #08456C",
                            height: "45%",
                            maxHeight: "45%",
                            minHeight: "45%",
                            width: isBrowser ? "360px" : "160px",
                          }
                    }
                    type={"text"}
                    error={firstNameError}
                    keyToSet={"firstName"}
                    value={this.state.firstName}
                    handleChange={(prop) => (event) => {
                      this.setState({
                        // firstName: /^([a-zA-Z ']*)$/.test(event.target.value) ? event.target.value : '',
                        firstName: event.target.value,
                        // firstNameError: /^([a-zA-Z0-9- ' .]*)$/.test(
                        firstNameError: !/^[a-zA-Z]+$/.test(event.target.value),
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="body2" gutterBottom>
                    <span style={{ color: "#727272" }}>
                      {Properties.Label_LastName}
                    </span>
                  </Typography>
                  <CustomTextField
                    style={{
                      border: lastNameError
                        ? "1px solid red"
                        : "1px solid #08456C",
                      height: "45%",
                      maxHeight: "45%",
                      minHeight: "45%",
                      width: isBrowser ? "360px" : "160px",
                    }}
                    type={"text"}
                    keyToSet={"firstName"}
                    value={this.state.lastName}
                    handleChange={(prop) => (event) => {
                      const { value } = event.target;
                      this.setState({
                        lastName: value,
                        lastNameError: value && !/^[a-zA-Z]+$/.test(value),
                        // lastNameError: /^([a-zA-Z0-9- ' .]*)$/.test(event.target.value) ? false : true,
                      });
                    }}
                  />
                </Grid>
              </Grid>

              <Grid spacing={1} container className={classes.gridStyling}>
                <Grid item xs={6}>
                  <Typography variant="body2" gutterBottom>
                    <span style={{ color: "#727272" }}>
                      {Properties.Label_Gender}
                    </span>
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <div
                    style={{
                      width: isBrowser ? "360px" : "160px",
                    }}
                  >
                    <MultiSelect
                      value={this.state.gender}
                      error={genderError}
                      options={this.state.genderList}
                      onChange={this.handleGenderChange}
                    />
                  </div>
                </Grid>

                {this.state.showDOB && (
                  <Grid item xs={6}>
                    <Typography variant="body2" gutterBottom>
                      <span style={{ color: "#727272" }}>
                        {Properties.Label_DOB}
                      </span>
                      {dobMandatoryOnSignup && showDOB && (
                        <span style={{ color: "red" }}>*</span>
                      )}
                    </Typography>
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      disableFuture={true}
                      value={dob}
                      style={{
                        width: isBrowser ? "360px" : "160px",
                      }}
                      error={dobError}
                      className={classes.fieldWidth}
                      InputProps={{
                        classes: {
                          root: dobError
                            ? classes.datePickerError
                            : classes.datePicker,
                        },
                      }}
                      FormHelperTextProps={{
                        style: {
                          margin: "-3px 0px",
                        },
                      }}
                      keyboardIcon={
                        <IconCalendarBlue className={classes.calendarIconCss} />
                      }
                      onChange={(date) => {
                        if (date > new Date()) {
                          this.setState({ dobError: true });
                        } else
                          this.setState({
                            dob: this.isValidDate(date) ? date : "",
                            dobError: this.isValidDate(date) ? false : true,
                          });
                      }}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid
                // style={{ height: "75px", margin: "0px 0px 0px 20%" }}
                spacing={1}
                container
                className={classes.gridStyling}
              >
                <Grid item xs={6}>
                  <Typography variant="body2" gutterBottom>
                    <span style={{ color: "#727272" }}>
                      {Properties.Label_Country}
                    </span>
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <div
                    style={{
                      width: isBrowser ? "360px" : "160px",
                    }}
                  >
                    <MultiSelect
                      value={this.state.country} //{country}
                      error={countryError}
                      options={this.state.countryList}
                      onChange={this.handleCountryChange}
                    />
                  </div>
                </Grid>
                {this.state?.showPinCode && (
                  <Grid item xs={6}>
                    <Typography variant="body2" gutterBottom>
                      <span style={{ color: "#727272" }}>
                        {Properties.Label_Postal}
                      </span>{" "}
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <CustomTextField
                      style={
                        zipError
                          ? {
                              border: "1px solid red",
                              height: "45%",
                              maxHeight: "45%",
                              minHeight: "45%",
                              width: isBrowser ? "360px" : "160px",
                            }
                          : {
                              border: "1px solid #08456C",
                              height: "45%",
                              maxHeight: "45%",
                              minHeight: "45%",
                              width: isBrowser ? "360px" : "160px",
                            }
                      }
                      type={"text"}
                      keyToSet={"postaclCode"}
                      error={zipError}
                      value={this.state.zip}
                      handleChange={(prop) => (event) => {
                        this.setState({
                          zip: event.target.value,
                          zipError: false,
                        });
                      }}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid container>
                {isBrowser ? (
                  <>
                    {this.state.showPhone && (
                      <Grid
                        spacing={1}
                        container
                        xs={6}
                        className={classes.gridStyling}
                      >
                        <Grid item style={{ width: "97%" }}>
                          <Typography variant="body2" gutterBottom>
                            <span style={{ color: "#727272" }}>
                              {Properties.Label_ContatNumber}
                            </span>
                            {emailAndContactMandatoryOnRegistration &&
                              !byMobile && (
                                <span style={{ color: "red" }}>*</span>
                              )}
                          </Typography>
                          <Grid
                            item
                            xs={6}
                            style={{
                              display: "flex",
                              width: isBrowser ? "360px" : "160px",
                            }}
                          >
                            <Grid
                              item
                              style={{
                                width: "40%", //window.innerWidth > "600" ? "120px" : "40px",
                              }}
                            >
                              <MultiSelect
                                value={contactCode}
                                options={this.state.countryCodeList}
                                onChange={this.handleCodeChange}
                                showIcon={true}
                              />
                            </Grid>
                            <Grid
                              item
                              style={{
                                width: "60%", // window.innerWidth > "600" ? "240px" : "40px",
                              }}
                            >
                              <CustomTextField
                                style={
                                  !ApplicationUtil.validateContactNumber(
                                    this.state?.contactNumber
                                  ) && contactNumberError
                                    ? {
                                        border: "1px solid red",
                                        height: "63%",
                                        maxHeight: "63%",
                                        minHeight: "63%",
                                        marginLeft: "2px",
                                        backgroundColor: byMobile
                                          ? "#D1D5DB"
                                          : "",
                                      }
                                    : {
                                        border: "1px solid #08456C",
                                        height: "63%",
                                        maxHeight: "63%",
                                        minHeight: "63%",
                                        marginLeft: "2px",
                                        backgroundColor: byMobile
                                          ? "#D1D5DB"
                                          : "",
                                      }
                                }
                                type={"number"}
                                error={contactNumberError}
                                keyToSet={"ContactNumber"}
                                value={contactNumber}
                                handleChange={(prop) => (event) => {
                                  if(event.target.value?.length === 0 && !emailAndContactMandatoryOnRegistration){
                                    this.setState({
                                      contactNumber: event.target.value,
                                      contactNumberError:false,
                                    })
                                  }else{

                                    this.setState({
                                      contactNumber: event.target.value,
                                      contactNumberError:
                                        // event.target.value.length != 10
                                        ApplicationUtil.validateContactNumber(
                                          event.target.value
                                        )
                                          ? false
                                          : true,
                                    });
                                  }
                                }}
                                readOnly={byMobile ? true : false}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </>
                ) : (
                  <>
                    {this.state.showPhone && (
                      <Grid
                        xs={6}
                        container
                        spacing={1}
                        className={classes.gridStyling}
                      >
                        <Grid item style={{ width: "97%" }}>
                          <Typography variant="body2" gutterBottom>
                            <span style={{ color: "#727272" }}>
                              {Properties.Label_ContatNumber}
                            </span>
                            {emailAndContactMandatoryOnRegistration &&
                              !byMobile && (
                                <span style={{ color: "red" }}>*</span>
                              )}
                          </Typography>
                          <Grid
                            item
                            // xs={6}
                            style={{
                              display: "flex",
                              // width: isBrowser ? "360px" : "160px",
                            }}
                          >
                            <Grid
                              item
                              style={{
                                width: "40%", //window.innerWidth > "600" ? "120px" : "40px",
                              }}
                            >
                              <MultiSelect
                                value={contactCode}
                                options={this.state.countryCodeList}
                                onChange={this.handleCodeChange}
                                showIcon={true}
                              />
                            </Grid>
                            <Grid
                              item
                              style={{
                                width: "60%", // window.innerWidth > "600" ? "240px" : "40px",
                              }}
                            >
                              <CustomTextField
                                style={
                                  !ApplicationUtil.validateContactNumber(
                                    contactNumber
                                  ) && contactNumberError
                                    ? {
                                        border: "1px solid red",
                                        height: "63%",
                                        maxHeight: "63%",
                                        minHeight: "63%",
                                        marginLeft: "2px",
                                        // width:
                                        //   window.innerWidth > "600" ? "60%" : "120px",
                                      }
                                    : {
                                        border: "1px solid #08456C",
                                        height: "63%",
                                        maxHeight: "63%",
                                        minHeight: "63%",
                                        marginLeft: "2px",
                                        // width:
                                        //   window.innerWidth > "600" ? "160px" : "120px",
                                      }
                                }
                                type={"number"}
                                error={contactNumberError}
                                keyToSet={"ContactNumber"}
                                value={this.state.contactNumber}
                                handleChange={(prop) => (event) => {
                                  if(event.target.value?.length === 0 && !emailAndContactMandatoryOnRegistration){
                                    this.setState({
                                      contactNumber: event.target.value,
                                      contactNumberError:false,
                                    })
                                  }else{
                                  this.setState({
                                    contactNumber: event.target.value,
                                    contactNumberError:
                                      ApplicationUtil.validateContactNumber(
                                        event.target.value
                                      )
                                        ? true
                                        : false,
                                  });
                                }
                                }}
                                readOnly={byMobile ? true : false}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
                {/* <Grid item xs={6}  >
                  <Typography variant="body2" gutterBottom>
                    <span style={{ color: "#727272",marginLeft:'12px' }}>
                      {Properties.Blood_Group_Label}
                    </span>
                    
                  </Typography>
                  <div
                    style={{
                      width: isBrowser ? "360px" : "160px",
                   marginLeft:'12px'
                    }}
                  >
                    <MultiSelect
                      value={this.state.bloodGroup}

                      // error={bloodGroupError}
                      options={ApplicationConstants.bloodGroupList}
                      onChange={this.handleBloodGroupChange}
                    />
                  </div>
                </Grid> */}

                {/* -----x----- */}
                <Grid
                  style={{
                    margin: "0px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                  container
                  xs={6}
                  spacing={1}
                >
                  <Grid item>
                    <Typography variant="body2" gutterBottom>
                      <span style={{ color: "#727272" }}>Profile Pic</span>
                    </Typography>

                    <Badge
                      overlap="circle"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      badgeContent={
                        <label htmlFor="profile-file">
                          {/* //<EditIcon  // */}
                          <PhotoCamera
                            style={{
                              color: "#F79824",
                              cursor: "pointer",
                              zIndex: "0",
                            }}
                          />
                        </label>
                      }
                      style={{ zIndex: "0" }}
                    >
                      <Grid item xs style={{ display: "none" }}>
                        <input
                          className={classes.input}
                          id="profile-file"
                          type="file"
                          //handling for single image selection
                          onClick={(event) => {
                            event.target.value = null;
                          }}
                          onChange={(e) => {
                            this.multipleUploadPost(e);
                          }}
                        />
                      </Grid>
                      <Avatar
                        alt={"profile_pic"} //+"profile_pic"
                        src={this.state.profilePicturePreview}
                        className={classes.avatarSize}
                      >
                        {/* <PhotoCamera /> */}
                      </Avatar>
                    </Badge>
                  </Grid>
                  {this.state.profilePicturePreview && (
                    <IconButton
                      onClick={(e) => this.handleImageDelete()}
                      size="small"
                    >
                      <DeleteTrashIcon />
                    </IconButton>
                  )}
                </Grid>
              </Grid>

              {/* ------x----- */}

              {this.state.showHeightWeightFields && (
                <Grid
                  style={{ width: "100%" }}
                  className={classes.gridStyling}
                  container
                  spacing={1}
                >
                  <Grid item xs={6}>
                    <Typography variant="body2" gutterBottom>
                      <span style={{ color: "#727272" }}>
                        {Properties.Height_label} {Properties.Label_unit_cm}
                      </span>
                      <span style={{ color: "red" }}>*</span>
                    </Typography>{" "}
                    <CustomTextField
                      style={
                        heightError
                          ? {
                              border: "1px solid red",
                              height: "45%",
                              maxHeight: "45%",
                              minHeight: "45%",
                              width: isBrowser ? "360px" : "160px",
                            }
                          : {
                              border: "1px solid #08456C",
                              height: "45%",
                              maxHeight: "45%",
                              minHeight: "45%",
                              width: isBrowser ? "360px" : "160px",
                            }
                      }
                      type={"number"}
                      keyToSet={"postaclCode"}
                      error={heightError}
                      value={this.state.height}
                      handleChange={(prop) => (event) => {
                        this.setState({
                          height: event.target.value,
                          heightError: false,
                        });
                      }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body2" gutterBottom>
                      <span style={{ color: "#727272" }}>
                        {Properties.Weight_label} {Properties.Label_Unit_kg}
                      </span>
                      <span style={{ color: "red" }}>*</span>
                    </Typography>{" "}
                    <CustomTextField
                      style={
                        weightError
                          ? {
                              border: "1px solid red",
                              height: "45%",
                              maxHeight: "45%",
                              minHeight: "45%",
                              width: isBrowser ? "360px" : "160px",
                            }
                          : {
                              border: "1px solid #08456C",
                              height: "45%",
                              maxHeight: "45%",
                              minHeight: "45%",
                              width: isBrowser ? "360px" : "160px",
                            }
                      }
                      type={"number"}
                      keyToSet={"postaclCode"}
                      error={weightError}
                      value={this.state.weight}
                      handleChange={(prop) => (event) => {
                        this.setState({
                          weight: event.target.value,
                          weightError: false,
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              )}
              {/* ------x---- */}
              {/* {this.state.showRunnerGroupList && (
                <Grid
                  style={{ width: "100%", margin:'20px 0px' }}
                  container
                  spacing={1}
                >
                  <Grid item sm={6} xs={12}>
                    <Typography variant="body2" gutterBottom>
                      <span style={{ color: "#727272" }}>
                        {Properties.Label_FitnessGroup}
                      </span>
                    </Typography>{" "}
                    <div
                      style={{
                        width: isBrowser ? "360px" : "100%",
                      }}
                    >
                      <MultiSelect
                      isGroup
                      groupedOptions={runnerGroupList}
                      isClearable
                      formatOptionLabel={(option,{context}) =>{
                         return context === 'value' ? option?.city ? `${option.label} (${option.city.toUpperCase()})` : option.label : option.label
                      }
                        }
                        // options={this.state.runnerGroupList}
                        value={this.state.selectedRunnerGroup}
                        onChange={this.handleRunnerGroupChange}
                      />
                    </div>
                  </Grid>
                  {this.state.otherGroup ? (
                    <Grid container spacing={1} xs={12} style={{margin:'20px 0px'}} >

                    <Grid item xs={6}>
                    <Typography variant="body2" gutterBottom>
                      <span style={{ color: "#727272" }}>
                        {'Group Name'}
                      </span>
                    </Typography>
                      <CustomTextField
                        style={{
                          border: "1px solid #08456C",
                          height: "48%",
                          maxHeight: "48%",
                          minHeight: "48%",
                          // width:'100%'
                          // marginTop: "21px",
                          width: isBrowser ? "360px" : "160px",
                        }}
                        type={"text"}
                        keyToSet={"runnerGroup"}
                        value={runnerGroup}
                        handleChange={(prop) => (event) => {
                          this.setState({ runnerGroup: event.target.value });
                        }}
                      />
                      
                    </Grid>
                    <Grid item xs={6}>
                    <Typography variant="body2" gutterBottom>
                      <span style={{ color: "#727272" }}>
                        {Properties.Label_City}
                      </span>
                    </Typography>
                      <CustomTextField
                        style={{
                          border: "1px solid #08456C",
                          height: "48%",
                          maxHeight: "48%",
                          minHeight: "48%",
                          // marginTop: "21px",
                          width: isBrowser ? "360px" : "160px",
                        }}
                        type={"text"}
                        keyToSet={"runnerGroup"}
                        value={runnerGroupCity}
                        handleChange={(prop) => (event) => {
                          this.setState({ runnerGroupCity: event.target.value });
                        }}
                      />
                      
                    </Grid>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
              )} */}

              {this.state.showCheckBoxField && (
                <Grid style={{ margin: "0px" }} container>
                  <Grid item xs={6}>
                    <span style={{ fontSize: "16px" }}>
                      <input
                        type="checkbox"
                        onChange={this.handleCheckBoxChange}
                        value={this.state.CheckboxSelected}
                      />
                      {Properties.show_Public_Event_Label}
                    </span>
                  </Grid>
                </Grid>
              )}

              <Grid
                style={{
                  width: "100%",
                  height: "75px",
                  marginTop: "10px",
                  //display: "flex",
                  justifyContent: "center",
                  margin: "0px",
                  textAlign: "center",
                }}
                container
                spacing={1}
              >
                {isMobile ? (
                  <>
                    <Grid style={{ marginTop: "5%" }} item xs={5}>
                      <SubmitCancelBtn
                        onClick={() => this.cancelFields()}
                        label={"Cancel"}
                        type="submit"
                      />
                    </Grid>
                    <Grid style={{ marginTop: "5%" }} item xs={5}>
                      <SubmitCancelBtn
                        onClick={() => this.ValidateFields()}
                        label={"Next"}
                        type="submit"
                      />
                    </Grid>
                  </>
                ) : (
                  <Grid
                    // style={{ marginTop: "5%" }}
                    container
                    spacing={1}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item xs={3}>
                      <SubmitCancelBtn
                        onClick={() => this.cancelFields()}
                        label={"Cancel"}
                        type="submit"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <SubmitCancelBtn
                        onClick={() => this.ValidateFields()}
                        label={"Next"}
                        type="submit"
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </div>
          )}
          <DialogBox
            signup={true}
            open={this.state.openWarning}
            message={this.getDialogComponent()}
            onClick={() => this.navigate()}
            closeDisloag={() => this.closeWindowWarning()}
          />
        </div>
        <DialogBox
          signup={true}
          open={this.state.open}
          message={this.state.dailogMsg}
          header={this.state.dialogHeader}
          onClick={() => {
            this.navigateToEventList();
          }}
          fullWidth
          maxWidth={"xs"}
          closeDisloag={() => this.closeWindow()}
        />
        <ImageDialogBox
          imagePath={this.state.image_src}
          open={this.state.imageDialog}
          onClick={() => this.setState({ imageDialog: false })}
          closeDisloag={() => this.setState({ imageDialog: false })}
        />
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.user,
    userSelectedEvent: state.user.userSelectedEvent,
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps, {
    updateUserDetails,
    updateUserSelectedEvent,
    updateUserSelectedProgram,
  })
)(RegistrationContainer);
